<template>
    <div class="panel">
        <button 
            class="toggle-btn edit"
            id='edit-btn'
            @click="toggleEdit"
            :class="{'active': this.editMode}"
            type="button"
        >
            <img src="@/assets/img/edit.svg" alt="edit icon">
            <span class="button-text">Edit Mode</span>
        </button>

        <button 
            class="toggle-btn delete" 
            id="delete-btn"
            @click="toggleDelete" 
            :class="{'active': this.deleteMode}"
            type="button"
        >
            <img src="@/assets/img/delete.svg">
            <span class="button-text">Delete Mode</span>
        </button>
    </div>
</template>

<script>
export default {
    props: ['deleteMode', 'editMode'],
    methods: {
        toggleEdit() {
            this.$emit('set-edit-mode', !this.editMode);
        },
        toggleDelete() {
            this.$emit('set-delete-mode', !this.deleteMode);
        }
    },
    // computed: {
    //     isNotSafe() {
    //         return !this.$store.state.uiStore.isSafe
    //     },
    //     hasSubjects() {
    //         return this.$store.getters.hasActiveSubjects
    //     }
    // }
}
</script>

<style lang="stylus" scoped>

div.panel 
    // background-color: hsla(0, 0%, 0%, 0.1)
    position fixed
    bottom 1rem
    left 21rem
    padding 1rem
    border-radius: 7px

    button.toggle-btn
        border none
        display flex
        flex-flow row
        align-items center
        padding 1rem
        background var(--panel)
        border-radius 7px
        text-decoration none
        text-align center
        color var(--text)
        font-family var(--font)
        font-size 1.5rem
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        // margin 1rem
        width 100%

        img 
            margin-right .5rem
            height 2rem
            filter var(--svg-filter)

        &:hover
            cursor pointer 

    button.edit 
        margin-bottom: 1rem

    button.active 
        background var(--accent)
        color white

@media screen and (max-width: 1200px)
    span.button-text 
        display: none
    div.panel 
        button.toggle-btn
            img 
                margin-right 0

@media screen and (max-width: 1000px)
    div.panel 
        left 1rem

@media screen and (max-width: 600px)
    div.panel
        padding 0
        button.toggle-btn
            padding 0.75rem
            img 
                height 1.5rem
                margin-right 0
</style>
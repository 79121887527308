<template>
    <div>
        <slot></slot>
    </div>
</template>

<style lang="stylus" scoped>
    div
        display flex
        flex-flow row
</style>
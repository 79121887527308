<template>
    <div class="center-panel">
        <div class="inner-panel">
            <h2>Email Address</h2>
            <p>{{email}}</p>
            <h2>Creation Date</h2>
            <p>{{created}} </p>
            <h2>Account Status</h2>
            <p>{{status}} </p>
            <h2>Payment Actions</h2>
            <div class="button-row">
                <button class="subscribe-button" v-if="status == 'Trialing'" @click="subscribe">Subscribe</button>
                <button v-if="status == 'Current'"><a :href="updateUrl">Update Payment</a></button>
            </div>
            <h2>Account Actions</h2>
            <div class="button-row">
                <button><router-link to='/auth/reset'>Reset Password</router-link></button>
                <button class="delete" @click="openConfirm()">Delete Account</button>
            </div>
        </div>
    </div>
    <popup v-if="isDeleteConfirm" @close="isDeleteConfirm = false">
        <delete-confirm @close="isDeleteConfirm = false"/>
    </popup>
</template>

<script>
import apiCalls from '@/services/apiCalls'
// import DeleteConfirm from '@/components/common/DeleteConfirm.vue'
import DeleteConfirm from './DeleteConfirm.vue'
import Popup from '@/components/common/Popup.vue'
import auth from '@/middleware/auth'
import subscription from '@/middleware/subscription'
export default {
    components: { Popup, DeleteConfirm },
    data() {
        return {
            created: '',
            status: '',
            updateUrl: '',
            isDeleteConfirm: false
        }
    },
    computed: {
        email() {
            return auth.getEmail();
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        const payload = {
            userId: auth.getOwner()
        };
        apiCalls.getUserDetails(payload).then(response => {
            if (response.data) {
                this.created = response.data.created.substring(0, 10);
                this.status = response.data.status;
                this.updateUrl = response.data.updateUrl;
            }
        });
    },
    methods: {
        openConfirm() {
            this.isDeleteConfirm = true;
        },
        subscribe() {
            subscription.subscribe();
        }
    }
}
</script>

<style lang="stylus" scoped>

    div.center-panel 
        margin auto
        margin-top 3rem
        margin-bottom 3rem

        div.inner-panel
            background var(--brighter-panel)
            border-radius 12px
            padding 3rem
            display flex
            flex-flow column

            h2 
                font-size 1.75rem
                margin 0

            p
                font-size 1.5rem
                margin 0.5rem 0.5rem 2.5rem 0.5rem

            a
                text-decoration: none
                color: var(--text)
            
            button 
                padding 0.75rem 0
                font-family: var(--font)
                background-color var(--accent)
                color: var(--text)
                margin 1rem
                border: none
                border-radius: 7px;
                font-size: 1.3rem;
                box-shadow 1px 1px 3px 1px rgba(0,0,0,.2)

                &:hover 
                    transform scale(1.03)
                    cursor pointer
                    box-shadow 1px 1px 3px 1px rgba(0,0,0,.2)

                a
                    padding 0.75rem
                    border-radius 7px

            button.subscribe-button
                padding 0.75rem

            button.delete
                background-color var(--warn)
                padding 0.75rem

            div.button-row 
                display: flex
                flex-direction: row
                margin 0 0 1.5rem 0


@media screen and (max-width: 1000px)
    div.center-panel 
        div.inner-panel
            h2 
                font-size 1.5rem
            p
                font-size 1.3rem
                margin-bottom 2rem
            button 
                font-size 1.2rem
            div.button-row 
                margin 0 0 1rem 0

@media screen and (max-width: 600px)
    div.center-panel 
        div.inner-panel
            text-align center
            h2 
                font-size 1.3rem
            p
                font-size 1.1rem
                margin-bottom 1.5rem
            div.button-row 
                flex-direction: column
                margin 1rem 0 1rem 0
            button 
                align-self: center
                font-size 1rem
                margin 0.5rem
    

</style>
import auth from './auth.js'

export default {

    subscribe() {
        let current = window.location.href; 
        let ind = current.lastIndexOf('/');
        let url = current.substring(0, ind) + '/projects';
        Paddle.Checkout.open({
            product: 23726,
            email: auth.getEmail(),
            disableLogout: true,
            success: url
        });
    }
}

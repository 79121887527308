<template>
    <div class="greeting" v-if="visible">
        <transition name="fade">
            <div class="background" tabindex="0">
                <div class="foreground" v-click-away="makeInvisible">
                    <h1 class="heading">Welcome to ShutterSort</h1>
                    <p class="message">
                        Our platform is in active development, with a planned launch for Summer 2022.
                        In the meantime, check out our site to learn more.
                    </p>
                    
                </div>
            </div>
        </transition>
        
    </div>
</template>

<script>
import { directive } from "vue3-click-away";

export default {
    directives: {
        ClickAway: directive
    },
    data() {
        return {
            visible: true
        }
    },
    methods: {
        makeInvisible() {
            this.visible = false;
        }
    }
}
</script>

<style lang="stylus" scoped>
@keyframes background-transition {
    0% {
        background-position 1% 0%
    }
    50% {
        background-position 99% 100%
    }
    100% {
        1% 0%
    }
}
div.greeting
    
    div.background
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        z-index: 100;
        font-family var(--font)
        // max-width 45rem
        margin auto
        background-color: hsla(0, 0%, 0%, 0.8)

    
        div.foreground
            background-image: (linear-gradient(to left, hsla(208, 25%, 40%, 1), hsla(312, 25%, 40%, 1)));
            animation: background-transition 9s ease infinite;
            display: flex
            flex-flow column
            flex-grow: 1
            align-items: center
            background-size: 400% 400%;
            color var(--text)
            font-size 1.75rem
            border-radius: 7px;
            box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
            max-width 40rem
            margin auto
            padding: 2rem;

            p.message
                font-size 1.5rem
                margin 0.5rem

            h1.heading
                color white
                font-size 2.5rem


    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    @media screen and (max-width: 1000px)
        div.background
            div.foreground
                padding: 1.5rem;
                max-width: 30rem
                p.message
                    font-size 1.3rem
                    margin 0.5rem

                h1.heading
                    color white
                    font-size 2rem
                

    @media screen and (max-width: 600px)
        div.background
            div.foreground
                padding: 1rem;
                max-width: 20rem
                p.message
                    font-size 1.1rem
                    margin 0.5rem

                h1.heading
                    color white
                    font-size 1.75rem

    @media screen and (max-width: 400px)
        div.background
            div.foreground
                max-width 20rem

</style>
<template>
    <div class="panel">
        <h1>How it Works</h1>
        <div class="content">
            <div class="segment">
                <h2>Before Photo Shoot</h2>
                <hr>
                <p>Log into the web-app. It can be accessed on a laptop, tablet, or even a smart phone.</p>
                <img src="@/assets/img/explain/web.png">
                <p>Create a new project, and upload subject data.</p>
                <img src="@/assets/img/explain/data.png" class="invert">
            </div>
            <div class="segment">
                <h2>During Photo Shoot</h2>
                <hr>
                <p>Log into the web-app.</p>
                <img src="@/assets/img/explain/web.png">
                <p>Use the web-app to search for each subject as they arrive for photographing.</p>
                <img src="@/assets/img/explain/search.png">
                <p>Click on a subject to view their unique image code. Photograph this code prior to taking photos of the subject.</p>
                <img src="@/assets/img/explain/code.png">
            </div>
            <div class="segment">
                <h2>After Photo Shoot</h2>
                <hr>
                <p>Log into the desktop-app.</p>
                <img src="@/assets/img/explain/desktop.png">
                <p>Point the desktop-app to the directory containing the photos from the photo shoot.</p>
                <img src="@/assets/img/explain/photos.png">
                <p>Configure the desired sorting structure, and sort the photos.</p>
                <img src="@/assets/img/explain/structure.png">
                <p>A new directory will be created that contains the photos sorted into your specified structure.</p>
                <img src="@/assets/img/explain/sorted.png" class="invert">
            </div>
        </div>
        <p>
            For more detailed information, check out the <router-link to='/docs'>documentation</router-link> page.
        </p>
    </div>
</template>

<style lang="stylus" scoped>
    div.panel
        margin auto
        margin-top 8rem
        width: 100%
        div.content
            display: flex
            flex-direction: row
            width: 100%
            div.segment
                width: 33%
                padding 0 5% 0 5%
        h1 
            font-weight normal
            font-size 2.5rem
        h2 
            font-size 1.75rem
        p
            font-size 1.25rem
        img.invert
            filter: invert(0.9)
        img 
            width: 75%
        a
            color: var(--link-text)

@media all and (max-width: 1000px) 
    div.panel
        div.content 
            div.segment 
                padding 0 3% 0 3%
        h1 
            font-size 2rem
        h2 
            font-size 1.5rem
        p
            font-size 1.1rem
        img 
            width: 100%

@media all and (max-width: 600px) 
    div.panel
        div.content 
            div.segment 
                padding 2%
        h1 
            font-size 1.5rem
        h2 
            font-size 1.2rem
        p
            font-size 0.9rem

</style>
import { apiClient } from './apiClient'

export default {

    fetchProjects(payload) {
        return apiClient.post('list-projects', payload)
    },

    deleteProject(payload) {
        return apiClient.post('delete-project', payload)
    },

    fetchSubjects(payload) {
        return apiClient.post('list-subjects', payload)
    },

    addSubject(payload) {
        return apiClient.post('add-subject', payload)
    },

    editSubject(payload) {
        return apiClient.post('update-subject', payload)
    },

    deleteSubject(payload) {
        return apiClient.post('delete-subject', payload)
    },

    parseCSV(payload) {
        let form = new FormData()
        form.append('data', payload)
        return apiClient.post('parse-subject-data', form)
    },

    createProject(payload) {
        return apiClient.post('add-project', payload)
    },

    renameProject(payload) {
        return apiClient.post('rename-project', payload)
    },

    loadSubjects(payload) {
        return apiClient.post('load-subjects', payload)
    },
    
    addUser(payload) {
        return apiClient.post('add-user', payload)
    },

    getUserDetails(payload) {
        return apiClient.post('user-details', payload)
    },

    deactivateUser(payload) {
        return apiClient.post('deactivate-user', payload)
    },

    recordLogin(payload) {
        return apiClient.post('record-login', payload)
    }
}


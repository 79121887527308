<template>
<div>
    <div class="center-panel">
        <div class="inner-panel">
            <file-select />
            <p>Upload data from a CSV file to create your project. This is the recommended method for creating projects, as it loads all subjects at once. Note that subjects can still be added and edited after the project has been created.</p>
        </div>
        <div class="inner-panel">
            <button @click="this.$router.push('/app/create-empty-project')">Create Empty Project</button>
            <p>Define the types of data you want to associate with each subject, and manually add subjects later. Note that this is only recommended for small projects where the subjects are not known beforehand.</p>
        </div>
    </div>
</div>
</template>

<script>
import FileSelect from './FileSelect'
export default {

    components: { FileSelect },
    
}
</script>

<style scoped lang='stylus'>

div.center-panel
    margin auto
    margin-top 3rem
    margin-bottom 3rem
    width 80%
    max-width 80rem

    div.inner-panel
        background var(--brighter-panel)
        border-radius 12px
        margin 2rem
        display flex
        flex-flow column
        align-items center
        justify-content center
        padding 2rem
        overflow hidden

        button  
            padding 1.25rem
            background var(--accent)
            border-radius 7px
            border none
            margin 1rem

            color var(--text)
            font-size 1.75rem
            font-family var(--font)
            display flex 
            flex-flow row
            align-items center
            box-shadow 1px 1px 3px 1px rgba(0,0,0,.2)

            &:hover
                cursor pointer
                transform scale(1.03)

        p
            font-size: 1.4rem
            text-align: center

@media screen and (max-width: 1000px) 
    div.center-panel
        margin-top 3rem
        margin-bottom 2rem
        div.inner-panel
            button
                font-size 1.4rem
            p
                font-size: 1.2rem

@media screen and (max-width: 600px) 
    div.center-panel
        width 90%
        margin-top 1rem
        div.inner-panel
            button
                font-size 1.1rem
                padding 1rem
            p
                font-size: 1rem

</style>
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import getters from './getters'
import mutations from './mutations'
import projectActions from './modules/projectActions'
import subjectActions from './modules/subjectActions'
import authActions from './modules/authActions'
import uiStore from './modules/uiStore'
import uploadStore from './modules/uploadStore'

export default createStore({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
        paths: ['projects', 'listLimit', 'activeSubjects', 'activeProject', 'uiStore.isSafe', 'uiStore.quitAsking']
    })],
    modules: {
        projectActions,
        subjectActions,
        authActions,
        uiStore,
        uploadStore
    },
    state: {
        projects: [],
        listLimit: 100, 
        subjectToEdit: {},
        activeSubjects: [],
        activeProject: {}
    },
    getters,
    mutations,
});

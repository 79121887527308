import LoginPage from '@/pages/auth/LoginPage'
import SignUpPage from '@/pages/auth/SignUpPage'
import ResetPage from '@/pages/auth/ResetPage'

export default [
    {
        path: 'login',
        component: LoginPage
    },
    {
        path: 'sign-up',
        name: 'sign-up',
        component: SignUpPage
    },
    {
        path: 'reset',
        name: 'reset',
        component: ResetPage
    }
]
<template>
    <form key="resetPw" @keydown.enter="validate">
        <p>Enter email to reset password</p>
        <email-form-input @email-change="setEmail"/>
        <action-button button-text="Send" @click="validate"/>
    </form>         
</template>

<script>

import EmailFormInput from '@/components/auth/EmailFormInput'
import ActionButton from '@/components/auth/ActionButton'
// import authMixin from '@/mixins/authMixin.js'
import auth from '@/middleware/auth'

export default {
    components: { EmailFormInput, ActionButton },
    // mixins: [authMixin],
    data() {
        return {
            email: null,
            formIsValid: true,
        }
    },
    methods: {
        setEmail(value) {
            this.email = value;
        },
        validate() {
            this.formIsValid = true;
            if (!auth.isValidEmail(this.email)) {
                this.formIsValid = false;
                this.$store.dispatch('callToast', "Error: Please enter valid email.")
                return;
            } else { 
                this.reset()
            }
        },
        async reset() {
            await this.$store.dispatch('resetPassword', this.email)
        }
    }    

}
</script>


<style lang="stylus" scoped>
    form 
        display flex
        flex-flow column
    p
        color var(--text)
        font-family var(--font)
        font-size 1.2rem
        
    a, a:hover, a:focus, a:active 
        text-decoration: none;
        color: inherit;
        margin-left 0.1rem
    a
        font-family var(--font)
        color var(--text)
        font-size 1.1rem
        &:hover
            cursor pointer
            color white

@media all and (max-width: 600px)
    a
        font-size 0.9rem
    p
        font-size 0.9rem

</style>

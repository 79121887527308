import apiCalls from "../../services/apiCalls"
import auth from "@/middleware/auth"

export default {
actions: {
    async fetchSubjects ({ rootState, commit }) { // eslint-disable-line
        let payload = {
            'owner': auth.getOwner(),
            'project': rootState.activeProject.id
        }
        try {
            const res = await apiCalls.fetchSubjects(payload)
            if(res.data.length === 0) {
                await commit('setActiveSubjects', [])
            } else {
                await commit('setActiveSubjects', res.data)
                // await commit('setGroups')
            }
            
        } catch (err) {
            err => console.log('actions - fetchSubjects ☻ ', err)
        }
        
    },

    async addSubject({dispatch, rootState}, payload ) {

        const subject = {
            "owner":auth.getOwner(),
            "project":rootState.activeProject.id,
            "firstName": payload.firstName,
            "lastName":payload.lastName,
            "groups": payload.groups
        };

        try {
            await apiCalls.addSubject(subject)
            // dispatch('callToast',  `${subject.lastName}, ${subject.firstName} added`)
        } catch (err) {
            dispatch('callToast', `Error: ${err.message}`)
        }
    },

    async editSubject({dispatch}, payload ) {
        console.log(payload);
        const subject = {
            "owner":auth.getOwner(),
            "id": payload.id,
            "firstName": payload.firstName,
            "lastName": payload.lastName,
            "groups": payload.groups
        };

        try {
            await apiCalls.editSubject(subject)
            // dispatch('callToast', `${subject.firstName} ${subject.lastName} edited`)
        } catch (err) {
            dispatch('callToast', `Error: ${err.message}`)
        }
    },

    async deleteSubject({ rootState }, payload) {

        const subject = JSON.stringify({
            "owner": auth.getOwner(),
            "project": rootState.activeProject.id,
            "subject": payload
        });
        await apiCalls.deleteSubject(subject)
    },
}
}

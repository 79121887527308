<template>
    <div class="base" ref="base">
        <router-view/>
        <legal-notice/>
    </div>
</template>


<script>
import LegalNotice from '@/components/common/LegalNotice.vue'
export default {
    components: {
        LegalNotice
    },

    methods: {
        resizeWindow() {
            const height = window.innerHeight.toString() + "px";
            this.$refs.base.style.height = height;
        }
    },

    mounted() {
        window.addEventListener("resize", this.resizeWindow);
        this.resizeWindow();
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.resizeWindow);
    }
}
</script>

<style lang="stylus" scoped>


div.base
    display: flex
    flex-direction: column
    flex-grow: 1
    // height 100vh
    div.legal-notice
        text-align: center
    background-image: url('../../assets/img/background-2.png')
    background-attachment: fixed;
    background-position: center;
    
@media all and (max-width: 1000px) 
    div.legal-notice
        font-size: 0.9rem
    
@media all and (max-width: 600px) 
    div.legal-notice
        font-size: 0.8rem
</style>
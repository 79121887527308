<template>
<div class="container">
    <div class="panel-div">
        <about-section/>
        <features-section/>
        <pricing-section/>
        <subscribe-section/>
        <support-section/>
        <greeting/>
    </div>
</div>
    
</template>

<script>
import AboutSection from './AboutSection.vue'
import FeaturesSection from './FeaturesSection.vue'
import PricingSection from './PricingSection.vue'
import SubscribeSection from './SubscribeSection.vue'
import SupportSection from './SupportSection.vue'
import Greeting from '../Greeting.vue'
export default {
    components: {
        AboutSection,
        FeaturesSection,
        PricingSection,
        SubscribeSection,
        SupportSection,
        Greeting
    }
}
</script>

<style lang="stylus" scoped>
div.container
    div.panel-div 
        margin: auto;
        margin-top: 8rem;
        width: 80%;
        padding: 3%;
        // background: var(--main-panel)
        border-radius: 1em;
        max-width: 60rem;
        color hsla(0, 0%, 80%, 1)
        
        div ::v-deep(p)
            text-align: center;
            font-size: 1.3rem;
        
        div ::v-deep(h1)
            font-size: 2.75rem;
            margin: 1rem;
            font-weight 400
        
        div ::v-deep(h2)
            font-size: 1.75rem;
        
        div ::v-deep(a.anchor) 
            display: block;
            position: relative;
            visibility: hidden;
            top: -6rem
            margin-top: 6rem;
        
        div ::v-deep(hr)
            width: 60%;
            margin: auto;
            color: rgb(72, 61, 165);
            border: 2px solid;
            border-radius: 1px;
    

@media all and (max-width: 1000px) 
    div.container
        div.panel-div 
            margin-top: 10rem;
        
            div ::v-deep(a.anchor)
                top: -8rem;
                margin-top: 5rem;
            
            div ::v-deep(h1)
                font-size: 2.5rem;
            
            div ::v-deep(p)
                font-size: 1.2rem;
        

    

@media all and (max-width: 600px)
    div.container
        div.panel-div 
            margin-top: 8rem;
        
            div ::v-deep(a.anchor) 
                top: -8rem;
                margin-top: 2rem;
            
            div ::v-deep(h1)
                font-size: 2rem;

            div ::v-deep(h2)
                font-size: 1.5rem;
            
            div ::v-deep(hr)
                width: 55%;
            
            div ::v-deep(p)
                font-size: 1.1rem;
        
    
</style>
<template>
    <button 
        alt="toggle showing password or hiding password"
        type="button"
        class="show-hide"
        :class="{visible: isVisible}"
        @click.prevent="toggleVisibility"
    >
        <img 
            v-if="isVisible"
            src="@/assets/img/show-password.svg"     
            alt="show password icon"
        >
        <img 
            v-else
            src="@/assets/img/no-show-password.svg" 
            alt="hide password icon"
        >
    </button>
</template>

<script>
export default {
    emits: ["togglepw"],
    data() {
        return {
            isVisible: false,
            
        }
    },
    methods: {
        toggleVisibility() {
            this.isVisible = !this.isVisible
            this.$emit("togglepw")
        },
    }
}
</script>

<style lang="stylus" scoped>
    button.show-hide
        background-color transparent
        border: none
        position absolute
        right .5rem 
        // top 1vh 
        align-self center    

        &:hover 
            transform scale(1.05)
            cursor pointer
        
        img 
            height 1.7rem
            width 1.7rem
            // filter var(--svg-filter)


</style>
<template>
    <div class="main-panel" >
        <slot></slot>
    </div>
</template>

<style lang="stylus" scoped>
    div.main-panel
        position fixed
        top 6.5rem
        left 20rem
        width calc(100vw - 20rem)
        height calc(100% - 6.5rem)
        background var(--panel)
        border-top-left-radius 10px;
        display flex
        flex-flow column
        align-items center
        overflow-y auto
        overflow-x hidden
        font-family var(--font)
        color var(--text)
        

@media screen and (max-width: 1000px) 
    div.main-panel 
        top 10rem
        left 0
        border-radius 10px 10px 0 0
        width 100%
        overflow-y auto
        height calc(100% - 10rem)

@media screen and (max-width: 800px) 
    div.main-panel 
        top 9.5rem
        height calc(100% - 9.5rem)

@media screen and (max-width: 600px) 
    div.main-panel 
        top 9rem
        height calc(100% - 9rem)
</style>
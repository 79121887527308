<template>
    <div class="delete-confirm">
        <h1>Warning!</h1>
        <p>You are about to delete your account. All data associated with this account will be deleted, and your subscription will be cancelled.</p>
        <p>This action is permanent and cannot be undone. To proceed, please type your password below.</p>
        <div class="pw-input">
            <input ref="pwInput" type="password" v-model="password">
        </div>
        <action-button
            button-text="Cancel"
            button-type="cancel"
            @click="closeDialog"
        />
        <action-button
            class="submit-button"
            v-if="password.length > 0"
            v-bind:button-text="buttonText"
            v-bind:button-type="buttonType"
            v-on:action="verify"
        />
    </div>
</template>

<script>
import auth from '@/middleware/auth.js'
import authCalls from '@/services/authCalls.js'
import apiCalls from '@/services/apiCalls.js'
import ActionButton from '@/components/auth/ActionButton.vue'
export default {
    components: {
        ActionButton
    },
    data() {
        return {
            password: '',
            buttonText: 'Delete',
            buttonType: 'warn'
        }
    },
    methods: {
        async verify() {
            try {
                this.$emit('close');
                // yes, we really do need to login again...
                // IDP needs a very recent token in order to successfully delete an account,
                // and even doing a normal token refresh does not work
                const payload = {
                    email: auth.getEmail(),
                    password: this.password
                };
                const res = await authCalls.login(payload);
                auth.setToken(res.data.idToken);
                auth.setRefresh(res.data.refreshToken);
                await apiCalls.deactivateUser({userId: auth.getOwner()});
                await this.$store.dispatch("logout");
                this.$router.push('/auth/login');
            } catch {
                // TODO
            }
            
        },
        closeDialog() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="stylus" scoped>

    div.delete-confirm
        text-align: center
        margin-bottom 1rem

        h1
            font-size 2.5rem

        p 
            font-size 1.5rem
        
        div.pw-input
            margin 3rem 0 1rem 0

            input 
                font-size: 1.5rem
                border-radius: 5px
                width: clamp(14rem, 60%, 20rem)
                padding: 0.25rem
                background-color: hsla(0, 0%, 100%, 0.2)
                color: var(--text)
                text-align: center

        button.submit-button
            margin-left: 2rem

@media all and (max-width: 1000px)
    div.delete-confirm
        margin-bottom 0.75rem

        h1
            font-size 2rem

        p 
            font-size 1.25rem

        div.pw-input
            input 
                font-size 1.25rem
        
        button.submit-button
            margin-left: 1.5rem

@media all and (max-width: 600px)
    div.delete-confirm
        margin-bottom 0.5rem

        h1
            font-size 1.5rem

        p 
            font-size 1rem

        div.pw-input
            input 
                font-size 1rem
        
        button.submit-button
            margin-left: 1rem
    
</style>
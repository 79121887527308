<template>
    <div class="email-row">
        <input 
            class="email" 
            :class="{'longEmail': hideEmailBackground}" 
            type="text" 
            placeholder="Email"
            name="email" 
            v-model.trim="email"
        >
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: null,
            hideEmailBackground: false,
        }
    },
    watch: {
        email: function (val) {
            if (val.length > 18) {
                this.hideEmailBackground = true;
            } else {
                this.hideEmailBackground = false;
            }
            this.$emit('email-change', this.email);
        }
    }
}
</script>

<style lang="stylus" scoped>
    div.email-row
        position: relative
        display flex
        flex-flow row
        margin 0.75rem
        border none

        input.email
            align-content: center
            width 100%
            font-size 1.3rem
            padding .7rem 1.3rem
            border none
            border-radius 7px
            color var(--text)
            text-align center
            background-color var(--panel)
            background-image url(../../assets/img/email.svg)
            background-repeat no-repeat
            background-position 1rem
            background-size 1.7rem 1.7rem
            font-family var(--font)

            &:placeholder 
                opacity .6
                text-align center
                color var(--text)

            &:focus::placeholder 
                color transparent

        input.longEmail 
            background-image none

@media all and (max-width: 600px)
    div.email-row
        margin 0.5rem
        align-self center
        width 100%
        input.email
            font-size 1rem

</style>

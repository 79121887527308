<template>
    <div class="container">
        <!-- <hr>
            <flex-row class="modes">
                <h2>Dark Mode</h2>
                <color-switch/>
                <h2>Light Mode</h2>
            </flex-row> -->
        <div class="block">
            <h2>Safe Mode</h2>
            <flex-row class="safe">
                <h3 class="off">Off</h3>
                <safe-switch/>
                <h3 class="on">On</h3>
            </flex-row>
            <p>Disable all project modifications and account actions. This setting is recommended if allowing subjects to pull up their own information during a photo shoot.</p>
        </div>

        <div class="block" :class="{disabled: isSafe}">
            <h2>List Render Limit</h2>
            <select class="limit-select" :class="{disabled: isSafe}" v-model="listLimit">
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
            </select>
            <p>When searching for subjects in the project view, the list of matches will only appear when there are fewer subjects than the render limit.</p>
        </div>
        
        <div class="block" :class="{disabled: isSafe}">
            <flex-row class="quit">
                <h2>Quit Asking</h2> 
                <quit-check-box/>
            </flex-row>
            <p>No more pop-ups asking if you <em>really</em> want to delete a subject. Note that you will still be asked to confirm when deleting projects.</p>
        </div>
        
    </div>
</template>

<script>
import QuitCheckBox from '@/components/common/QuitCheckBox'
// import ColorSwitch from './ColorSwitch'
import SafeSwitch from './SafeSwitch'

export default {

    components: { QuitCheckBox, SafeSwitch },

    computed: {
        theme() {
            return localStorage.getItem('theme')
        },
        listLimit: {
            get() {
                return this.$store.state.listLimit
            },
            set(val) {
                this.$store.commit('setListLimit', val)
            }
        },
        isSafe() {
            return this.$store.state.uiStore.isSafe;
        }
    },

    watch: {
        theme: function(res) { console.log(res) }
    }
    
}
</script>

<style lang="stylus" scoped>
div.container 
    margin auto
    margin-top 1rem
    max-width calc(100% - 6rem)

    p
        font-size: 1.25rem
        margin-bottom 0
    
    h2 
        font-size 2rem
        margin 0
    h3 
        font-size 1.5rem
        font-weight normal
        margin 0
    h3.off 
        // margin-left 3rem
        margin-right 1rem
    h3.on 
        margin-left 1rem

    div.block
        background: var(--panel)
        padding 2rem
        margin-top 2rem
        margin-bottom 2rem
        border-radius: 7px
        max-width 60rem

        div.safe 
            align-items center
            margin-top 1rem

        div.quit
            align-items center
            h2 
                margin-right 1rem
                align-items: center

        select.limit-select
            margin-top 1rem
            font-size 1.5rem
            background var(--brightest-panel)
            border-radius: 5px
            border 3px solid var(--brightest-panel)
            padding 0.25rem
            color var(--text)

            option 
                background var(--brightest-panel)
                border-radius: 5px
                color black

            &:hover
                color white
                cursor pointer

            



        select.disabled
            pointer-events: none
            color var(--charcoal)
            background-color: var(--panel)
            border 3px solid var(--panel)


    div.disabled
        color var(--greyed-out-text)
        background-color var(--greyed-out)

@media screen and (max-width: 1000px) 
    div.container 
        margin-top 1rem
        max-width: calc(100% - 6rem)

        p
            font-size: 1.1rem

        h2 
            font-size 1.75rem

        h3 
            font-size 1.4rem

        div.block
            select.limit-select
                font-size 1.25rem

@media screen and (max-width: 600px) 
    div.container 
        margin-top 0
        max-width: calc(100% - 4rem)

        p
            font-size: 1rem

        h2 
            font-size 1.5rem

        h3 
            font-size 1.25rem

        div.block
            select.limit-select
                font-size 1.1rem
</style>
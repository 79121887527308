<template>
<div>

    <div class="left-align">
        <template v-for="(project, i) in projects" :key="i">
            <div :class="{'first-grid': false}" >
                <button 
                    :class="{'first-project': i===0}" 
                    class="project-link" 
                    @click="setProject(project)"
                >
                    <div class="project-name">{{project.name}}</div>
                    <div class="details">
                        <div>Created: {{project.created.substring(0, 10)}}</div>
                    </div>
                </button>
            </div>
        </template>
    </div>

</div> 
</template>

<script >
export default {
    
    methods: {

        async setProject(project) {
            this.$store.commit('setActiveProject', project);
            this.$store.commit('setSelectsAll')
            await this.$store.dispatch('fetchSubjects');
            this.$router.push({name: 'find-subjects', params: {id: project.id}});
        }
        
    },

    computed: {

        projects() {
            var list = this.$store.state.projects;
            var comparison = (a, b) => {
                const aDateTime = Date.parse(a.created);
                const bDateTime = Date.parse(b.created);
                return (aDateTime < bDateTime) ? 1 : -1
            };
            list.sort(comparison);
            return list;
        },
    },
}
</script>

<style scoped lang='stylus'>
div.left-align
    align-items start
    margin auto
    margin-top 3rem
    width 100%
    
    button.project-link
        color: var(--text)
        text-decoration: none;
        font-family: var(--font)
        background var(--brighter-panel)
        border-radius 7px
        width calc(100vw - 26rem)
        max-width 60rem
        padding 1rem
        margin 0 0 1.5rem 0
        border none
        display flex
        justify-content space-between

        &:hover 
            transform scale(1.02)
            cursor pointer 
            opacity 1

        div.project-name    
            font-size 2.5rem
            align-self center
            margin-right 2rem
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

        div.details
            font-size 1.5rem
            align-self center

    div.first-grid
        display grid 
        width 100%
        grid-template-columns 1fr 1fr

        button.first-project 
            width 100%

        span.toggletip-container 
            margin-left 1rem

@media screen and (max-width: 1000px) 
    div.left-align
        margin-top 2rem
        button.project-link
            width calc(100vw - 4rem)
            div.project-name    
                font-size 2rem
            div.details
                font-size 1.25rem

@media screen and (max-width: 600px) 
    div.left-align
        button.project-link 
            div.project-name    
                font-size 1.25rem
            div.details
                font-size 0.8rem

@media screen and (max-width: 400px) 
    div.left-align
        button.project-link 
            div.project-name    
                font-size 1rem

</style>
<template>
    <div class="flex-container">
        <name-logo class="name-logo"/>
        <div class="navigation">
            <div class="sections">
                <router-link v-scroll-to="'#about'" to='/'>About</router-link>
                <router-link v-scroll-to="'#features'" to='/'>Features</router-link>
                <router-link v-scroll-to="'#pricing'" to='/'>Pricing</router-link>
                <router-link v-scroll-to="'#subscribe'" to='/'>Subscribe</router-link>
                <router-link v-scroll-to="'#support'" to='/'>Support</router-link>
                <router-link @click="scrollToTop" id="download-link" to='/download'>Download</router-link>
            </div>
            <div class="auth">
                <router-link class="login" to='/auth/login'>Login</router-link>
                <router-link class="sign-up" to='/auth/sign-up'>Sign Up</router-link>
            </div>
            
        </div>
    </div>
</template>

<script>
import NameLogo from './NameLogo.vue'

export default {
    components: {
        NameLogo
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    }
}
</script>

<style lang="stylus" scoped>

div.flex-container  
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(44, 30, 63);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    filter: drop-shadow(0px 5px 3px rgba(0, 0, 0, .16));
    z-index: 10;
    padding 1rem
        
    div.navigation  
        display: flex;
        list-style: none;
        align-self: center;
        flex-direction: row;
        align-items: center;
        font-family var(--font)

        div.sections 
            text-align: center
            display: flex
            flex-direction: row

        div.auth 
            text-align: center
            display: flex
            flex-direction: row
        a 
            display: block;
            margin-right: 0.4rem
            margin-left: 0.4rem
            color: var(--text)
            text-decoration: none;
            font-size: 1.2rem
            white-space: nowrap

            &:hover 
                color: rgb(195, 195, 195);
                text-decoration: underline;
                text-decoration-color: rgb(200, 150, 250)

        a.login 
            margin-left: calc(6vw - 1.5rem);



/* *********** media queries *********** */
@media all and (max-width: 1000px) 
    div.flex-container 
        flex-direction: column;

        h1.name-logo.logo
            font-size 2.3rem
        
        div.navigation
            margin-top 0.5rem
    
        #download-link
            display: none;


/* *********** media queries *********** */
@media all and (max-width: 600px) 
    div.flex-container 
        flex-direction: column;
        padding-top 0.4rem

        h1.name-logo.logo
            font-size 1.6rem
        
        div.navigation
            flex-direction: column;

            div.auth 
                margin-top 0.5rem

            a
                font-size 1rem
    
        #download-link
            display: none;

</style>
<template>
    <div class="container">
        <div class="outline">
            <div><router-link v-scroll-to="'#create-project'" to='/docs'>Create Project</router-link></div>
            <div><router-link v-scroll-to="'#manage-projects'" to='/docs'>Manage Projects</router-link></div>
            <div><router-link v-scroll-to="'#search-subjects'" to='/docs'>Search Subjects</router-link></div>
            <div><router-link v-scroll-to="'#manage-subjects'" to='/docs'>Manage Subjects</router-link></div>
            <div><router-link v-scroll-to="'#app-settings'" to='/docs'>App Settings</router-link></div>
            <div><router-link v-scroll-to="'#manage-account'" to='/docs'>Manage Account</router-link></div>
            <div><router-link v-scroll-to="'#sort-photos'" to='/docs'>Sort Photos</router-link></div>
        </div>
        <div class="content-container">
            <div class="content">
                <a class="anchor" id="create-project"></a>
                <h1>Create Project</h1>
                <p>
                    There are two ways to create a new project. While the preferred method is to create
                    a project from a CSV file, you can also choose to create an empty project and add
                    subjects one-by-one.
                </p>
                <h2>From CSV File</h2>
                <p>
                    In order to create a project from a CSV file, you must first have a CSV in the correct
                    format. The requirements for the CSV are as follows:
                </p>
                <ol>
                    <li>
                        A column header for the subject's first name. This name must contain both "first" 
                        and "name" (case insensitive). Valid examples include "first-name", "First Name", 
                        "first_name", etc.
                    </li>
                    <li>
                        A column header for the subject's last name. This name must contain both "last" 
                        and "name" (case insensitive). Valid examples include "last-name", "Last Name", 
                        "last_name", etc.
                    </li>
                    <li>
                        No more than 10 columns can be present in the CSV file.
                    </li>
                    <li>
                        No more than 10,000 rows can be present in the CSV file.
                    </li>
                    <li>
                        The file must be a true CSV file. Excel, Open Office, etc. are not currently supported.
                        However, it is trivial to export spreadsheets as CSV files in virtually every
                        spreadsheet software. As a reference for Excel, check <a href="https://support.microsoft.com/en-us/office/save-a-workbook-to-text-format-txt-or-csv-3e9a9d6c-70da-4255-aa28-fcacf1f081e6">here</a>.
                    </li>
                </ol>
                <p>An example valid CSV snippet:</p>
                <div class="center-img">
                    <img class="small invert" src="@/assets/img/docs/csv-sample.png" alt="">
                </div>
                <p>
                    Start by clicking "Create Project from File" from the Create Project page.
                </p>
                <img src="@/assets/img/docs/create-project-page.png" alt="">
                <p>
                    After selecting the CSV file to upload, give the project a name and click "Upload".
                </p>
                <img src="@/assets/img/docs/csv-upload.png" alt="">
                <p>
                    At this point, you will be asked to select up to three categories to be included. Note that only first and last
                    name are technically required, though most projects will likely make use of one or more categories. When done, 
                    click "Finish" to complete creating the project.
                </p>
                <img src="@/assets/img/docs/category-selection.png" alt="">
                <h2>Empty Project</h2>
                <p>
                    Alternatively, you can choose to create an empty project. However this will mean that you
                    need to add subjects one at a time. Creating an empty project is only recommended in cases
                    where you do not have any subject information prior to the photo shoot, and only expect a 
                    limited number of subjects.
                </p>
                <p>
                    Start by clicking "Create Empty Project" from the Create Project page.
                </p>
                <img src="@/assets/img/docs/create-project-page.png" alt="">
                <p>
                    Now give the project a name, and select up to three categories for each subject. Note that 
                    it is not required to have any categories, though we expect most projects will.
                </p>
                <img src="@/assets/img/docs/empty-project-form.png" alt="">
                <a class="anchor" id="manage-projects"></a>
                <h1>Manage Projects</h1>
                <p>
                    From the Manage Projects page, you can either rename a project or delete a project.
                </p>
                <img src="@/assets/img/docs/manage-projects.png" alt="">
                <p>
                    To rename a project, simply click on the pencil and give the project a new name. Then click the save button.
                </p>
                <img src="@/assets/img/docs/rename-project.png" alt="">
                <p>
                    To delete a project, click on the [X] and enter the project name to confirm deletion.
                </p>
                <img src="@/assets/img/docs/delete-project.png" alt="">
                <a class="anchor" id="search-subjects"></a>
                <h1>Search Subjects</h1>
                <p>
                    To search subjects, first select the desired project from the Projects page.
                </p>
                <img src="@/assets/img/docs/projects.png" alt="">
                <p>
                    This will bring up a list of subjects. Scroll through the list of subjects, or use the search bar to find a subject.
                </p>
                <img src="@/assets/img/docs/musicians-project.png" alt="">
                <p>
                    To view the subject's image code, just click on the subject's name.
                </p>
                <img src="@/assets/img/docs/image-code.png" alt="">
                <a class="anchor" id="manage-subjects"></a>
                <h1>Manage Subjects</h1>
                <p>
                    When viewing the subjects of a particular project, you also have the option to add, edit, and delete subjects. This is 
                    useful for cases in which a subject was not included in the original CSV file, or a subject's information was incorrect. 
                    Note that the functionality to edit and delete subjects is only active when Safe Mode is off (see App Settings for more 
                    details regarding Safe Mode). Regardless of whether Safe Mode is on or off, the functionality to add subjects always remains 
                    active.
                </p>
                <img src="@/assets/img/docs/musicians-project.png" alt="">
                <p>To add a subject, simply click on the Add Subject button and fill out the subject form.</p>
                <img src="@/assets/img/docs/add-subject.png" alt="">
                <p>To edit a subject, first enable Edit Mode by clicking on the Edit Mode button. This will display edit buttons next to each 
                    subject. Simply click the edit button next to the subject you wish to edit, and make changes to the form fields.
                </p>
                <img src="@/assets/img/docs/edit-mode.png" alt="">
                <p>Likewise, to delete a subject, first enable Delete Mode by clicking on the Delete Mode button. This will display delete buttons 
                    next to each subject. Simply click the delete button next to the subject you wish to delete. If confirmation is not disabled (see 
                    App Settings for more details), then you will be asked to confirm deleting the subject. Otherwise the subject will be deleted 
                    immediately upon clicking the delete button.
                </p>
                <img src="@/assets/img/docs/delete-mode.png" alt="">
                <a class="anchor" id="app-settings"></a>
                <h1>App Settings</h1>
                <p>
                    Click on the "Settings" tab to bring up the App Settings page. This is where you can configure options for your app.
                </p>
                <img src="@/assets/img/docs/settings.png" alt="">
                <p>
                    The first setting is the "Safe Mode" toggle. When switched on, the app will only allow the user to search and add subjects. This can 
                    be a useful feature if during a photo shoot, you allow subjects to pull up their own information (i.e. on a tablet). It prevents 
                    accidental editing or deletion of subjects/projects, as well as changes to account and app settings. In order to switch off safe mode, 
                    you will need to enter you password at the prompt.
                </p>
                <img src="@/assets/img/docs/safe-mode.png" alt="">
                <p>
                    The second setting specifies the list rendering limit. This controls how many subjects will automatically display in the Find Subjects 
                    page. If the number of subjects returned by the query is greater than the list render limit, the following message will appear:
                </p>
                <img src="@/assets/img/docs/too-many-subjects.png" alt="">
                <p>
                    The third setting controls whether or not you get a confirmation dialog when you delete subjects. By default, the app always asks you to 
                    confirm that you want to delete a subject. However, if you are deleting many subjects, you may wish to turn these dialogs off and 
                    streamline your workflow.
                </p>
                <a class="anchor" id="manage-account"></a>
                <h1>Manage Account</h1>
                <p>
                    To go to the Manage Account page, click the profile icon at the top right, and select "Manage Account". This will bring up the 
                    Manage Account page.
                </p>
                <img src="@/assets/img/docs/manage-account.png" alt="">
                <p>
                    For users with an active subscription, the status will display "Current", and you will have the option to update payment information. 
                    For users still in the free trial period, the status will read "Trialing", and you will have the option to subscribe at any time prior 
                    to the end of the free trial period.
                </p>
                <p>
                    Additionally, you have options to reset your account password, and delete your account. Note that deleting your account requires 
                    confirmation, and will automatically cancel your subscription (if active).
                </p>
                <a class="anchor" id="sort-photos"></a>
                <h1>Sort Photos</h1>
                <p>
                    Once a project's photo shoot is finished, you're ready to sort. If you haven't already downloaded the ShutterSort desktop client, 
                    then go ahead and do that at this point. Once installed, you can run the desktop client which brings up the login page.
                </p>
                <img src="@/assets/img/docs/desktop-login.png" alt="">
                <p>
                    Once logged in, you will be prompted to choose the project to sort.
                </p>
                <img src="@/assets/img/docs/desktop-project.png" alt="">
                <p>
                    The next step is to select the source directory containing the images from the photo shoot. Note that these files should be exported 
                    images in one of the following formats: JPEG (JPG), PNG, TIFF (TIF), or BMP. The directory should resemble the following:
                </p>
                <img src="@/assets/img/docs/source-dir.png" alt="">
                <p>
                    Note that the naming of the files must be sequential, which should automatically be ensured by virtually all camera models. Before 
                    each subject's images there should be an identifying image code. Click "Select" and navigate to the directory containing the source 
                    images.
                </p>
                <img src="@/assets/img/docs/desktop-source.png" alt="">
                <p>
                    After selecting the source images, you'll be prompted to select the output location, which is where the sorted photos will be placed. 
                    Note that the output directory must be 
                    empty, and if you select a directory that is not empty, you'll be asked to name a new directory which will be created inside the selected 
                    location.
                </p>
                <img src="@/assets/img/docs/desktop-target.png" alt="">
                <p>
                    The next step is to specify the naming convention for the sorted image files. The first drop-down determines the ordering of the subject's 
                    first and last names. For example, you can choose FIRST_LAST or LAST_FIRST. The second drop-down specifies the separator character. You can 
                    also choose to preserve the numbering from the original file if desired. An example of the file naming format will update and be shown at 
                    the bottom of the dialog.
                </p>
                <img src="@/assets/img/docs/desktop-convention.png" alt="">
                <p>Next, you'll be asked to specify the sorted directory structure. Choose from the drop-downs to determine the sorted output directory 
                    structure. Additionally, you can choose to place all subject images within a self contained subject directory. A visualization of the 
                    specified structure will update and be shown at the bottom of the dialog.
                </p>
                <img src="@/assets/img/docs/desktop-structure.png" alt="">
                <p>
                    At this point, you're ready to start the sort. A summary of the sort configuration will be shown. You'll also have the option to sample a 
                    single image per subject, which can be useful for use cases such as yearbook photo creation. Click "Sort" to begin sorting.
                </p>
                <img src="@/assets/img/docs/desktop-review.png" alt="">
                <p>
                    While the sort is taking place, you'll see the current progress displayed.
                </p>
                <img src="@/assets/img/docs/desktop-sorting.png" alt="">
                <p>
                    After the sort has finished, a summary of the results will be shown. The "Total Subjects" value denotes the total number of subjects loaded 
                    in the project, which could be lower than the total number of subjects for which photos were taken. The "Matched Subjects" value denotes the 
                    number of subjects for which corresponding photos were detected. The "Detected Errors" value denotes the number of matching errors which 
                    occurred, such as failing to read a subject's image code.
                </p>
                <img src="@/assets/img/docs/desktop-results.png" alt="">
                <p>
                    At this point, you can either close the desktop client or click "Start Another Sort" if you would like to resort this project or sort another 
                    project. The sorted photos will be available in the target output directory specified earlier. The top level of the sorted output directory 
                    contains two directories: "MATCHED" and "UNMATCHED". All subjects that were successfully matched will be in the "MATCHED" directory, and any 
                    subjects or subject photos that were not successfully matched will be placed in the "UNMATCHED" directory.
                </p>
                <img src="@/assets/img/docs/output-1.png" alt="">
                <p>
                    Based on the configuration of our example project, we can navigate the sorted directory structure as in these screenshots.
                </p>
                <img src="@/assets/img/docs/output-2.png" alt="">
                <img src="@/assets/img/docs/output-3.png" alt="">
                <img src="@/assets/img/docs/output-4.png" alt="">
                <img src="@/assets/img/docs/output-5.png" alt="">
                <p>
                    While we strive for maximal accuracy in terms of matching subjects via their image codes, occasionally errors can happen, such as if the image 
                    code was unreadable for some reason. Though these occurrences should be quite rare, we still aim to make the correction process as seamless as 
                    possible. Navigate to the "UNMATCHED" directory to see the photos which were not successfully matched to their respective subjects. In addition, 
                    a CSV file containing all unmatched subject information is created for convenience.
                </p>
                <img src="@/assets/img/docs/output-6.png" alt="">
            </div>
        </div>
    </div>
    
</template>

<style scoped lang="stylus">
    div.container
        margin auto
        margin-top 8rem

        div.outline 
            background: hsl(270, 30% 16%)
            width 16rem
            position fixed
            top 9rem
            left 3rem
            border-radius: 7px
            padding 1rem
            z-index 10

            div
                text-align: left
                margin 2rem 1rem 2rem 1rem
                a 
                    color var(--text)
                    font-size 1.3rem
                    text-decoration: none

                a:hover 
                    color white

        div.content-container
            display: flex
            flex-direction: row 
            justify-content: center
            div.content
                margin-left 22rem
                width clamp(30rem, 90% - 22rem, 70rem)
                
                text-align: left

                h1 
                    font-weight normal
                    font-size 2.25rem

                h2
                    font-weight normal
                    font-size 1.75rem

                a.anchor
                    display: block;
                    position: relative;
                    visibility: hidden;
                    top -6rem

                p 
                    font-size 1.2rem

                ol 
                    li
                        margin 1rem
                        font-size 1.2rem

                        a 
                            color var(--link-text)

                img 
                    width 100%
                    border 1px solid var(--charcoal)
                    margin auto

                div.center-img 
                    width 100%
                    display: flex
                    flex-direction: row
                    justify-content: center


                img.small 
                    width clamp(15rem, 60%, 30rem)

                img.invert
                    filter: invert(0.9)
                    z-index 2

@media all and (max-width: 1000px) 
    div.container 
        margin-top: 12rem;
        div.outline
            border-bottom 1px solid var(--charcoal)
            background: var(--bg)
            top 6.75rem
            left 0
            width 100%
            border-radius: 0
            display inline-flex
            flex-direction: row
            justify-content: space-between

            div
                margin 0.5rem 0.5rem
                a 
                    font-size 1rem
        div.content-container
            div.content 
                width 90%
                margin auto
                h1 
                    font-size 2rem
                h2 
                    font-size 1.5rem
                p 
                    font-size 1.1rem
                a.anchor
                    top -11rem
                ol 
                    li
                        font-size 1.1rem

 
@media all and (max-width: 600px) 
    div.container 
        margin-top: 11rem;
        div.outline
            top 6.5rem
            flex-direction: row
            padding 0.5rem 0
            
            div 
                text-align: center
                margin 0 0.2rem
                padding-bottom 0.2rem
                border-radius: 3px
                a 
                    font-size 0.75rem
        div.content-container
            div.content 
                width 90%
                margin auto
                h1 
                    font-size 1.5rem
                h2 
                    font-size 1.2rem
                p 
                    font-size 1rem
                a.anchor
                    top -10rem
                ol 
                    li
                        font-size 1rem

</style>
<template>
    <a @click="openCode" class='center' alt="link to subject's code page or edit subject page" >
        {{subject.lastName}}, {{subject.firstName}}
    </a>
</template>

<script>
export default {
    props: ['subject', 'name'],
    methods: {
        openCode() {
            this.$router.push({name: 'code', params: {id: this.subject.id}});
        }
    }
};
</script>

<style scoped lang="stylus">
a.center
    display inline-block
    grid-column-start 3
    text-decoration none
    color var(--text)
    font-weight 200
    font-family var(--font)
    font-size 1.75rem
    padding 0.3rem 0.5rem
    width 100%
    background-color var(--brighter-panel)
    border-radius 3px
    margin-bottom .2rem
    overflow-x: hidden

    &:hover 
        cursor pointer 
        background-color var(--brightest-panel)

@media screen and (max-width: 1000px)
    a.center
        font-size 1.5rem

@media screen and (max-width: 600px)
    a.center
        font-size 1.25rem

</style>
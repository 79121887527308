<template>
    <div class="legal-notice">
        <p>Copyright © 2022 Audax Design Ltd. All rights reserved.</p>
        <div>
            <router-link to='/'>Site</router-link>
            <span> | </span>
            <router-link to='/terms'>Terms</router-link>
            <span> | </span>
            <router-link to='/privacy'>Privacy</router-link>
            <span> | </span>
            <router-link to='/cookie'>Cookies</router-link>
            <!-- <span> | </span>
            <router-link to='/data'>Data</router-link> -->
        </div>
    </div>
</template>

<style lang="stylus" scoped>
    div.legal-notice
        font-family var(--font)
        text-align: center
        p
            color var(--slight-grey-text)
            margin-bottom 0
        span 
            color var(--slight-grey-text)
        div
            margin-top 0.25rem
            margin-bottom 1rem
            a
                color var(--link-text)
</style>
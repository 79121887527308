<template>
    <div>
        <a class="anchor" id="support"></a>
        <h1>Support</h1>
        <hr>
        <p>We believe that self service is the most efficient way to help you find answers. To that end, we have assembled extensive documentation and tutorials that explain the various aspects of the platform, as well as common issues to look out for. If you still need help, feel free to send us a message at support@shuttersort.io 
        </p>
        <div class="links">
            <div class="link-div">
                <button class="docs-button" @click="this.$router.push('/docs')">Documentation</button>
            </div>
            <!-- <div class="link-div">
                <a href="https://youtube.com" class="link">Video Tutorials</a>
            </div> -->
        </div>
        
    </div>
</template>

<style lang="stylus" scoped>

    div
        margin 0.5rem
        div.links
            margin 0
            display: flex
            flex-direction: row
            justify-content: center
        p 
            margin-bottom 2rem
        
        div.link-div
            margin 1rem

            button.docs-button
                padding: 0.75rem;
                padding-left: 1.75rem;
                padding-right: 1.75rem;
                font-weight: bold;
                font-size: 1.5rem;
                background-color: var(--accent);
                border-radius: 0.25rem;
                border: none;
                font-family var(--font)
                color var(--text)
    
                &:hover 
                    transform: scale(1.06)
                    cursor: pointer;

            a.link
                color var(--text)
                text-decoration none
                font-weight bold
                font-family var(--font)
                background-color: var(--accent)
                padding: 0.75rem;
                padding-left: 1.75rem;
                padding-right: 1.75rem;
                font-size: 1.5rem;
                border-radius: 0.25rem;
                display: inline-block

                &:hover
                    transform: scale(1.06)
                    cursor: pointer;

@media all and (max-width: 800px)
    div
        div.links 
            flex-direction: column


@media all and (max-width: 600px)
    div
        p
            margin-bottom: 1rem
        div.links
            margin-bottom 1rem
            div.link-div 
                margin 1.5rem 0 0 0
                button.docs-button 
                    font-size 1.1rem
                a.link
                    font-size 1.1rem

</style>
import apiCalls from "../../services/apiCalls"
import auth from "@/middleware/auth"

export default {
    state: {
        uploadHeaders:[],
        uploadRows: [],
        uploadProjectName: null,
        newId: null,
        // this really shouldn't be needed, but works for now...
        parseSuccess: false
    },
    mutations: {
        setNewProjectName(state, name) {
            state.uploadProjectName = name
        },
        setNewProjectHeaders(state, uploadHeaders) {
            const slicedHeaders = uploadHeaders.slice(2, uploadHeaders.length)
            state.uploadHeaders = slicedHeaders
        },
        setNewProjectRows(state, rows) {
            state.uploadRows = rows
        },
        setNewProjectId(state, id) {
            state.newId = id
        },
        setParseSuccess(state, flag) {
            state.parseSuccess = flag
        }
    },
    actions: {
        async parseCSV({ dispatch, commit }, payload) {
            try { 
                let res = await apiCalls.parseCSV(payload)
                commit('setNewProjectHeaders', res.data.header)
                commit('setNewProjectRows', res.data.rows)
                commit('setParseSuccess', true)
                // dispatch('callToast', 'CSV successfully parsed')
            } catch (err) {
                commit('setParseSuccess', false)
                dispatch('callToast', err.response.data)
            }
        },

        async createProject({dispatch, commit, state}, payload) {
            
            const project = {
                "owner":auth.getOwner(),
                "name":state.uploadProjectName,
                "schema":{
                    "header":[]
                }
            }
            project.schema.header.push(...payload)

            try {
                let res = await apiCalls.createProject(project);
                commit('setNewProjectId', res.data);
                // dispatch('callToast', `Project ${project.name} created`)
                
            } catch(err) {
                dispatch('callToast', `Error: ${err.message}`)
            }
        },

        async loadSubjects({ dispatch, state }, payload) {
            const project = payload.id
            const isHeaders = payload.headers
            const objToSend = { "subjects": []}
            const keys= state.uploadHeaders
            state.uploadRows.forEach((row) => {
                let sub = {
                    "owner": auth.getOwner(),
                    "project": project,
                    "firstName": row[0],
                    "lastName": row[1],
                    "groups": {}
                }
                if (isHeaders[0]) { sub.groups[keys[0]] = row[2] }
                if (isHeaders[1]) { sub.groups[keys[1]] = row[3] }
                if (isHeaders[2]) { sub.groups[keys[2]] = row[4] }
                if (isHeaders[3]) { sub.groups[keys[3]] = row[5] }
                if (isHeaders[4]) { sub.groups[keys[4]] = row[6] }
                
                
                objToSend.subjects.push(sub)
            })
            try {
                await apiCalls.loadSubjects(objToSend)
            } catch (err) {
                dispatch('callToast', `Error: ${err.message}`)
            }
        },


    }

}

<template>
    <form @keyup.enter="validate">
        <email-form-input @email-change="setEmail"/>
        <password-form-input @password-change="setPassword"/>
        <action-button button-text="Login" @click="validate" />
    </form>
    
    <router-link to='/auth/reset'>Reset Password</router-link>
</template>

<script>
import EmailFormInput from "@/components/auth/EmailFormInput.vue"
import PasswordFormInput from "@/components/auth/PasswordFormInput.vue"
import ActionButton from "@/components/auth/ActionButton.vue"
import auth from "@/middleware/auth"

export default {
    components: { EmailFormInput, PasswordFormInput, ActionButton },
    data() {
        return {
            email: null,
            password: null,
        }
    },
    methods: {
        setEmail(value) {
            this.email = value;
        },
        setPassword(value) {
            this.password = value;
        },
        validate() {
            if (!auth.isValidEmail(this.email) || !auth.isValidPassword(this.password)) {
                this.$store.dispatch('callToast', "Error: Please enter valid email and password.")
                return;
            } else { this.login()}
        },
        async login() {
            await this.$store.dispatch('login', {email: this.email, password: this.password})
        }
    }    
}
</script>

<style lang="stylus" scoped>
    form 
        display flex
        flex-flow column

        input.email 
            width 22rem
            font-size 1.3rem
            padding .7rem 1.3rem
            border none
            border-radius 7px
            color var(--text)
            text-align center
            background-color var(--panel)
            background-image url('../../..//assets/img/email.svg')
            background-repeat no-repeat
            background-position 1rem
            background-size 1.7rem 1.7rem
            font-family var(--font)

            &:placeholder 
                opacity .6
                text-align center
                color var(--text)

            &:focus::placeholder 
                color transparent

        input.longEmail 
            background-image none

        div.password-row 
            position relative
            display flex
            flex-flow row
            border none

            input.password
                background-image: url(../../../assets/img/password.svg)
                background-color var(--panel)
                border none
                background-repeat no-repeat
                background-position 1rem
                background-size 1.7rem 1.7rem
                font-family var(--font)
                color var(--text)
                font-size 1.3rem
                text-align center
                flex-grow 2
                width 22rem
                border-radius 7px
                padding .7rem 1.3rem

                &:placeholder 
                    opacity .6
                    text-align center
                    color var(--text)

                &:focus::placeholder 
                    color transparent
            
            input.longPassword 
                background-image none

        
    a, a:hover, a:focus, a:active 
        text-decoration: none;
        color: inherit;
        margin-left 0.1rem
    a
        font-family var(--font)
        color var(--text)
        font-size 1.1rem
        &:hover
            cursor pointer
            color white

@media all and (max-width: 600px)
    a
        font-size 0.9rem

</style>

<template>
    <div class="content-div">
        <p v-if="hasHeaders && this.chosenHeaders.length <= 3">The following categories have been detected. Select up to three to be included.</p>
        <p v-if="!hasHeaders"> No extra columns were detected in the CSV file beyond first and last name</p>
        <p v-if="this.chosenHeaders.length > 3" class="warn">Please choose a maximum of three categories</p>
        <div class="top-div">
            <div v-if="hasHeaders" class="header-div">
                <div class="header-rows" v-for="(head, i) in headers" :key="i">
                    <column-check-box :id="i" :headerName="head" @chooseheader="selectHeader($event)"/>
                </div>
            </div>
            <div class="table-div">
                <p>Preview</p>
                <table>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <template v-for="(head, i) in chosenHeaders" :key="i">
                            <th>{{head}}</th>
                        </template>
                    </tr>

                    <template v-for="(row, l) in rows.slice(0,5)" :key="l">
                        <tr>
                            <td>{{row[0]}}</td>
                            <td>{{row[1]}}</td>
                            <td v-if="isHeaders[0]">{{row[2]}}</td>
                            <td v-if="isHeaders[1]">{{row[3]}}</td>
                            <td v-if="isHeaders[2]">{{row[4]}}</td>
                            <td v-if="isHeaders[3]">{{row[5]}}</td>
                            <td v-if="isHeaders[4]">{{row[6]}}</td>
                            <td v-if="isHeaders[5]">{{row[7]}}</td>
                            <td v-if="isHeaders[6]">{{row[8]}}</td>
                            <td v-if="isHeaders[7]">{{row[9]}}</td>
                        </tr>
                    </template>
                </table>
            </div>
        </div>
        <div class="finish-div">
            <button class="close-button" @click="cancel">Cancel</button>
            <button class="finish-button" v-if="chosenHeaders.length < 4" @click="upload">Submit</button>
        </div>
    </div>
    

    

</template>

<script>
import ColumnCheckBox from './ColumnCheckBox.vue'
export default {

    components: {ColumnCheckBox},

    data() {
        return {
            isHeaders: [],
        }
    },

    methods: {

        selectHeader(payload) {

            this.isHeaders[payload.id] = !this.isHeaders[payload.id]
        },

        async upload() {
            await this.$store.dispatch('createProject', this.chosenHeaders)
            let id = this.$store.state.uploadStore.newId
            let payload = {"headers": this.isHeaders, "id": id}
            await this.$store.dispatch('loadSubjects', payload)
            await this.$store.dispatch('fetchProjects')
            this.$router.push({ name: "projects" });            
        },

        cancel() {
            this.$router.push('create-project')
        }

    },
    computed: {

        projectName() {
            return this.$store.state.uploadStore.uploadProjectName
        },

        chosenHeaders() {
            return this.headers.filter((head, i) => this.isHeaders[i] == true)
        },

        headers() {
            return this.$store.state.uploadStore.uploadHeaders
        },

        rows() {
            return this.$store.state.uploadStore.uploadRows
        },

        hasHeaders() {
            return this.headers.length > 0;
        }

    },
    created() {
        this.isHeaders = new Array(this.headers.length).fill(false)
    }
}
</script>

<style lang="stylus" scoped>
    h1 
        font-size 3rem
        font-weight normal

    h2
        font-size 2rem
        font-weight normal
    p 
        display block
        font-size 1.75rem
        margin-left 1vw
        margin-right 1vw
        margin-bottom 1.5rem
        text-align: center
    
    div.content-div
        width 80%
        max-width 80rem
        border-radius: 7px
        padding 0 1rem 1.5rem 1rem
        background-color: var(--panel)
        margin auto

        div.top-div
            display flex
            justify-content: flex-start

            div.header-div
                background-color: var(--brighter-panel)
                border-radius: 7px
                padding 1rem
                align-self: center
                margin-right 1rem
                margin-left 2rem

            div.table-div
                flex-grow 100
                margin auto
                margin-right 2rem
                margin-left 2rem
                overflow-y: auto
                white-space nowrap
                align-self: center
                background-color: var(--brighter-panel)
                padding 1rem
                border-radius: 7px

                p
                    margin-top 0

                table
                    border-collapse: separate
                    border solid var(--bg) 2px
                    border-radius 7px
                    background-color var(--bg)
                    font-size 1.25rem
                    margin auto
                    margin-bottom 1rem

                    tr:nth-child(even)
                        background-color: hsla(0, 0%, 100%, 0.15)
                    tr:nth-child(odd)
                        background-color: hsla(0, 0%, 100%, 0.1)
                    
                    th
                        padding 1rem
                    td
                        padding 0.25rem

        div.finish-div
            margin 3rem 0 1rem 0
            display flex
            justify-content: center
            button
                border-radius 7px
                padding 0.75rem 1rem
                margin 1.5rem 1rem 1rem 1rem
                font-size 1.5rem
                color white
                border none
                box-shadow 1px 1px 3px 1px rgba(0,0,0,.2)
                &:hover
                    transform scale(1.05)
                    cursor pointer
                    
            button.finish-button 
                background-color: var(--accent)
            button.close-button
                background-color: var(--brightest-panel)

    
            

    p.warn 
        color var(--warn)
        
@media screen and (max-width: 1000px) 
    h1 
        font-size 2.25rem
    h2
        font-size 1.75rem
    p 
        font-size 1.5rem
    table
        font-size 1.25rem
    div.content-div
        div.finish-div 
            button
                font-size 1.3rem
                padding 0.75rem 1.5rem 0.75rem 1.5rem
                margin 0 0.75rem 0 0.75rem


@media screen and (max-width: 600px)
    h1 
        font-size 1.75rem
    h2
        font-size 1.5rem
    p 
        font-size 1.25rem

    div.content-div
        div.top-div
            justify-content: center
        div.finish-div 
            margin-top 2rem
            button
                font-size 1.2rem
                padding 0.5rem 1rem 0.5rem 1rem
                margin 0 0.5rem 0 0.5rem

    div.table-div
        display none
    


</style>

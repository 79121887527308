<template>
<div class="outer-div">
    <template 
        v-for="(project, i) in projects" 
        :key="i" 
    >
        <flex-row>
            <div class="project" >

                <input 
                    type='text'  
                    v-model="editedName"
                    ref="input"
                    @keyup.enter="renameProject(project.id)"
                    v-if="projectIdToRename == project.id"
                >
                
                <div class="project-name" v-else>{{project.name}}</div>

                <button 
                    type="button" 
                    class="edit-btns save" 
                    @click="renameProject(project.id)"
                    v-if="projectIdToRename == project.id"
                >
                    <img src="@/assets/img/save.svg" alt="save icon">
                </button>

                <div class="btns-row">
                    
                    <button 
                        type="button"
                        class='edit-btns' 
                        @click="toggleEdit(project)"
                    >
                        <img src="@/assets/img/edit.svg" alt="edit icon">
                    </button>

                    <button 
                        type="button" 
                        class='edit-btns delete' 
                        @click="openDialog(project)"
                    >
                        <img src="@/assets/img/delete.svg" alt="delete icon">
                    </button>

                    
                </div>
            </div>
        </flex-row>
    </template>

</div>
<popup v-if="isDeleteConfirm" @close="isDeleteConfirm = false">
    <delete-confirm @close="isDeleteConfirm = false" :project="projectToDelete"/>
</popup>
</template>

<script>
import Popup from '@/components/common/Popup.vue'
import DeleteConfirm from './DeleteConfirm.vue'
export default {

    components: { DeleteConfirm, Popup },

    data() {
        return {
            
            isEditName: false,
            projectIdToRename: null,
            editedName: null,

            projectToDelete: null,
            isDeleteConfirm: false,
        }
    },

    methods: {
        async focusInput() {
            await new Promise(resolve => setTimeout(resolve, 1)); // idk why i have to do this
                                                                // hacky shit, but this is the 
                                                                // only way to get it to focus

            if(this.$refs.input) {
                this.$refs.input.focus()
            }
        },

        toggleEdit(project) {
            
            if (this.editedName === null) {
                this.focusInput()
                this.editedName = project.name
                this.projectIdToRename = project.id
            } else {
                this.editedName = null;
                this.projectIdToRename = null;
            }        
        },

        async renameProject(id) {
            let payload = {
                'id': id,
                'name': this.editedName,
            }
            
            await this.$store.dispatch('renameProject', payload);
            await this.$store.dispatch('fetchProjects');

            this.projectIdToRename = null;
            this.editedName = null;
        },

        openDialog(project) {
            this.projectToDelete = project;
            this.isDeleteConfirm = true;
        },
    },
    computed: {

        projects() {
            var list = this.$store.state.projects;
            var comparison = (a, b) => {
                const aDateTime = Date.parse(a.created);
                const bDateTime = Date.parse(b.created);
                return (aDateTime < bDateTime) ? 1 : -1
            };
            list.sort(comparison);
            return list;
        },

    },

    beforeCreate() {
        if(this.$store.state.projects.length < 1) {
            this.$store.dispatch('fetchProjects')
        }
        this.projects
    },
    
}
</script>

<style lang="stylus" scoped>
div.outer-div
    margin-top 3rem
    div.project
        color: var(--text)
        text-decoration: none;
        font-family: var(--font)
        background var(--brighter-panel)
        border-radius 7px
        width calc(100vw - 26rem)
        max-width 60rem
        padding 1rem
        margin 0 0 1.5rem 0
        border none
        display flex
        align-items: center

        input
            -webkit-appearance: none; 
            appearance: none;
            width: 25rem
            height 5 rem
            font-size 2.5rem
            border solid 2px var(--border)
            border-radius: 7px;
            box-sizing: border-box;
            color var(--text)
            text-align center
            background-color: var(--panel)
            font-family var(--font)
            
            &:focus
                background var(--bg)
                
            &::placeholder 
                opacity 1
                text-align center
                color var(--text)

            button.save-btn
                background var(--toast-success)
                font-family var(--font)
                border none
                font-size 1rem
                border-radius 3px
                position absolute
                right .3rem
                top .3rem


                &:hover 
                    transform scale(1.1)
                    cursor pointer

        div.project-name    
            font-size 2.5rem
            align-self center
            margin-right 2rem
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

        div.btns-row
            display: flex
            float right
            font-size 1.2rem
            align-self center
            margin-left auto
            margin-right 0

        button.edit-btns
                padding 0
                background none
                border none
                margin-right 1rem

                img 
                    height 2.5rem
                    vertical-align: middle
                    filter var(--svg-filter)

                &:hover
                    cursor pointer
                    transform scale(1.1)
        
        button.save
            filter: invert(100%)
            margin 1rem
            &:hover
                filter invert(100%) brightness(0.3) sepia(1) hue-rotate(100deg) saturate(12)

        button.delete
            &:hover
                filter brightness(0.3) sepia(1) hue-rotate(-70deg) saturate(12)

div.confirm-content
    margin-top: 1rem
    text-align: center
    h1 
        margin 1rem
        font-size 2.5rem
    span 
        font-family monospace
        font-weight bold

@media screen and (max-width: 1000px) 
    div.outer-div
        margin-top 2rem
        div.project
            width calc(100vw - 4rem)
            div.project-name    
                font-size 2rem
            button.edit-btns
                img 
                    height 2rem
            input
                width: 20rem
                height 4 rem
                font-size 1.5rem
    div.confirm-content
        h1 
            font-size 2.25rem

@media screen and (max-width: 600px)
    div.outer-div
        div.project
            div.project-name    
                font-size 1.25rem
            button.edit-btns
                img 
                    height 1.5rem
            input
                width: 13rem
                height 3 rem
                font-size 1.25rem

    div.confirm-content
        h1 
            font-size 2rem

@media screen and (max-width: 400px)
    div.outer-div
        div.project
            div.project-name    
                font-size 1rem
            input
                width: 9rem
                height 2.5 rem
                font-size 1rem

</style>
<template>
    <div class="page-container" ref="pageContainer">

        <a ref="back" alt="go back to previous page" @click="goBack()">
            <img src="@/assets/img/arrow.svg"/>
        </a>  

        <div ref="infoSection" class="info-section">
            <h1 class="info-label">First Name: {{truncate(subject.firstName)}}</h1>
            <h1 class="info-label">Last Name: {{truncate(subject.lastName)}}</h1>
            <h1 class="info-label" v-for="(attribute, key, i) in groups" :key="i">{{key}}: {{truncate(attribute)}}</h1>
        </div>

        <div class="code-section" ref="codeSection">
            <div class="pacman-border">
                <img ref="topLeft" src="@/assets/img/pacman.svg" alt="" class="top-left">
                <img ref="topRight" src="@/assets/img/pacman.svg" alt="" class="top-right">
                <img ref="bottomLeft" src="@/assets/img/pacman.svg" alt="" class="bot-left">
                <img ref="bottomRight" src="@/assets/img/pacman.svg" alt="" class="bot-right">

                <table class="table" ref="table">
                    <tr v-for="(row, i) in codeRows" :key=i>
                        <td class="table-elem" v-for="(symbol, j) in row" :key=j>
                            <span>{{symbol}}</span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            subject: {
                firstName: 'First Name',
                lastName: 'Last Name',
                code: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
                groups: {'key': 'value'}
            },
            firstNames : ['Liam', 'Olivia', 'Noah', 'Emma',
                          'Oliver', 'Ava', 'William', 'Sophia',
                          'Elijah', 'Isabella', 'James', 'Charlotte',
                          'Benjamin', 'Amelia', 'Lucas', 'Mia',
                          'Mason', 'Harper', 'Ethan', 'Evelyn',
                          'Alexander', 'Abigail', 'Henry', 'Emily',
                          'Jacob', 'Ella', 'Michael', 'Elizabeth',
                          'Daniel', 'Camila', 'Logan', 'Luna',
                          'Jackson', 'Sofia', 'Sebastian', 'Avery',
                          'Jack', 'Mila', 'Aiden', 'Aria',
                          'Owen', 'Scarlett', 'Samuel', 'Penelope',
                          'Matthew', 'Layla', 'Joseph', 'Chloe',
                          'Levi', 'Victoria', 'Mateo', 'Madison',
                          'David', 'Eleanor', 'John', 'Grace',
                          'Wyatt', 'Nora', 'Carter', 'Riley',
                          'Julian', 'Zoey', 'Luke', 'Hannah',
                          'Grayson', 'Hazel', 'Isaac', 'Lily',
                          'Jayden', 'Ellie'],
            lastNames : ['Smith', 'Johnson', 'Williams', 'Brown',
                         'Jones', 'Garcia', 'Miller', 'Davis',
                         'Rodriguez', 'Martinez', 'Hernandez', 'Lopez',
                         'Gonzales', 'Wilson', 'Anderson', 'Thomas',
                         'Taylor', 'Moore', 'Jackson', 'Martin',
                         'Lee', 'Perez', 'Thompson', 'White',
                         'Harris', 'Sanchez', 'Clark', 'Ramirez',
                         'Lewis', 'Robinson', 'Walker', 'Young',
                         'Allen', 'King', 'Wright', 'Scott',
                         'Torres', 'Nguyen', 'Hill', 'Flores',
                         'Green', 'Adams', 'Nelson', 'Baker',
                         'Hall', 'Rivera', 'Campbell', 'Mitchell',
                         'Carter', 'Roberts', 'Gomez', 'Phillips',
                         'Evans', 'Turner', 'Diaz', 'Parker',
                         'Cruz', 'Edwards', 'Collins', 'Reyes',
                         'Stewart', 'Morris', 'Morales', 'Murphy',
                         'Cook', 'Rogers', 'Gutierrez', 'Oritz',
                         'Morgan', 'Cooper'],
            schools: ["St. Christopher's", 'Steward', 'Collegiate',
                      "St. Catherine's", 'Trinity', 'Benedictine',
                      'Tucker', 'Deep Run', 'Godwin', 'Hermitage',
                      'Manchester', 'James River', 'Atlee', 'Varina',
                      'Freeman', 'Midlothian', 'Huguenot', 'Henrico',
                      'Monacan', 'St. Gertrude', 'New Community'],
            departments: ['Accounting', 'Sales', 'Finance', 'HR',
                          'Engineering', 'Facilities', 'Billing',
                          'Legal', 'Compliance', 'Operations', 'Trading']
        }
    },
    
    computed: {
        codeRows() {
            const chars = Array.from(this.subject.code);
            const rows = [];
            for (var i = 0; i < 8; i++) {
                rows.push(chars.slice(i * 8, (i + 1) * 8));
            }
            return rows;
        },
        groups() {
            let groups = this.subject.groups
            let sorted = Object.keys(groups).sort()
            let sortedObj = {}
            sorted.forEach((x) => {
                let key = x.charAt(0).toUpperCase() + x.slice(1)
                sortedObj[key] = groups[x]
            })                
            return sortedObj
        }
    },

    methods: {
        truncate(text) {
            text = String(text);
            if (text.length <= 16) {
                return text;
            } else {
                return text.substring(0, 13) + "...";
            }   
        },

        resizeComponents() {
            const windowHeight = window.innerHeight;
            this.$refs.pageContainer.style.height = windowHeight.toString() + "px";
            
            const codeHeight = this.$refs.codeSection.clientHeight;
            const codeWidth = this.$refs.codeSection.clientWidth;
            const tableDim = (codeWidth > codeHeight) ? codeHeight * 0.82 : codeWidth * 0.82;
            this.$refs.table.style.height = tableDim.toString() + "px";
            this.$refs.table.style.width = tableDim.toString() + "px";
            const tableFontSize = tableDim * 0.085;
            this.$refs.table.style.fontSize = tableFontSize.toString() + "px";
            const infoFontSize = Math.min(tableFontSize * 0.35, 24);
            this.$refs.infoSection.style.fontSize = infoFontSize.toString() + "px";
            const backSize = tableFontSize;
            this.$refs.back.style.height = backSize.toString() + "px";
            this.$refs.back.style.width = backSize.toString() + "px";
            const imageSize = tableDim * 0.11;
            const imageOffset = imageSize * (-0.5);
            this.$refs.topLeft.style.width = imageSize.toString() + "px";
            this.$refs.topLeft.style.top = imageOffset.toString() + "px";
            this.$refs.topLeft.style.left = imageOffset.toString() + "px";
            this.$refs.topRight.style.width = imageSize.toString() + "px";
            this.$refs.topRight.style.top = imageOffset.toString() + "px";
            this.$refs.topRight.style.right = imageOffset.toString() + "px";
            this.$refs.bottomLeft.style.width = imageSize.toString() + "px";
            this.$refs.bottomLeft.style.bottom = (imageOffset - tableFontSize * 0.2).toString() + "px";
            this.$refs.bottomLeft.style.left = imageOffset.toString() + "px";
            this.$refs.bottomRight.style.width = imageSize.toString() + "px";
            this.$refs.bottomRight.style.bottom = (imageOffset - tableFontSize * 0.2).toString() + "px";
            this.$refs.bottomRight.style.right = imageOffset.toString() + "px";
        },

        updateData() {
            
            const firstName = this.firstNames[Math.floor(Math.random() * this.firstNames.length)];
            const lastName = this.lastNames[Math.floor(Math.random() * this.lastNames.length)];
            const symbols = [];
            for (var i = 0; i < 64; i++) {
                var symbol = 'x';
                const rand = Math.random();
                if (rand > 0.25 && rand <= 0.5) {
                    symbol = 'o';
                } else if (rand > 0.5 && rand <= 0.75) {
                    symbol = '|';
                } else if (rand > 0.75 && rand <= 1.0) {
                    symbol = '-';
                }
                symbols.push(symbol);
            }
            const code = symbols.join('');
            let groups = {'key': 'value'};
            switch (Math.round(Math.ceil(Math.random() * 3 + 0.5))) {
                case 1: {
                    groups = {
                        'Advisor': this.lastNames[Math.floor(Math.random() * this.lastNames.length)],
                    }
                    break;
                }
                case 2: {
                    groups = {
                        'Advisor': this.lastNames[Math.floor(Math.random() * this.lastNames.length)],
                        'Grade': Math.floor(Math.random() * 12) + 1,
                    }
                    break;
                }
                case 3:
                    groups = {
                        'Advisor': this.lastNames[Math.floor(Math.random() * this.lastNames.length)],
                        'Grade': Math.floor(Math.random() * 12) + 1,
                        'School': this.schools[Math.floor(Math.random() * this.schools.length)]
                    }
                    break;
                case 4:
                    groups = {
                        'Department': this.departments[Math.floor(Math.random() * this.departments.length)],
                        'Manager': this.lastNames[Math.floor(Math.random() * this.lastNames.length)]
                    }
                    break;
                case 5:
                    groups = {
                        'Manager': this.lastNames[Math.floor(Math.random() * this.lastNames.length)]
                    }
                    break;
            }
            this.subject = {
                firstName: firstName,
                lastName: lastName,
                code: code,
                groups: groups
            };
        }
    },

    mounted() {
        window.addEventListener("resize", this.resizeComponents);
        this.resizeComponents();
        window.setInterval(() => this.updateData(), 1000);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.resizeComponents);
    }
    
}
</script>
<style scoped lang="stylus">
    div.page-container
        background white
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        display: flex;

        a 
            position: absolute;
            border: none;
            background: none;
            outline: none;
            z-index 1
            transform rotate(180deg)
            top 1rem
            left 1rem

            img 
                filter brightness(0)

            &:hover 
                transform: scale(1.2) rotate(180deg)
                cursor: pointer 
    
        div.info-section 
            font-family: 'noto_monoregular';
            color: black;
            overflow: hidden;
            display: flex;
            flex-flow column
            h1.info-label
                margin 0
                padding 0.5vmin
    
        div.code-section
            display: flex;
            justify-content: center
            align-items: center
            flex-grow: 1

            div.pacman-border 
                position relative
                display inline-block

                .top-left
                    transform rotate(180deg)
                    position absolute
                .top-right
                    transform rotate(270deg)
                    position absolute
                .bot-left
                    transform rotate(90deg)
                    position absolute
                .bot-right
                    position absolute
                
                table.table 
                    position inline-block
                    table-layout fixed
                    font-family: 'noto_monoregular'
                    font-weight: bold;
                    color: black;
                    background-color white
                    margin 0
        
                    td.table-elem
                        text-align: center
                        padding 0
                        margin 0



@media (orientation: landscape)
    div.page-container 
        flex-flow row
        div.info-section
            justify-content: center
            align-items: flex-start
            width 35%
            h1.info-label
                margin 1rem
                margin-bottom: 1vmin
                margin-left 10%
        div.code-section
            div.pacman-border 
                margin-right: 1rem


        

@media (orientation: portrait)
    div.page-container 
        flex-flow column
        div.info-section
            justify-content: center
            height 30%
            text-align: center
            align-items: center
            margin-top clamp(5rem, 7vmin, 7vmin)
            h1.info-label
                margin 0 0 0.5vmin 0
        div.code-section
            div.pacman-border 
                margin-bottom: 1rem


</style>

import router from '../../router'
import auth from '@/middleware/auth'
import authCalls from '@/services/authCalls.js'
import apiCalls from '@/services/apiCalls.js'

export default {
    actions: {
        async login({ commit, dispatch }, payload) {
            try {
                auth.clear();
                commit('clear'); // just an extra safety precaution
                let res = await authCalls.login(payload);
                let emailVerified = await dispatch('isEmailVerified', res.data.idToken);
                if (emailVerified) {
                    auth.setEmail(res.data.email);
                    auth.setOwner(res.data.localId);
                    auth.setToken(res.data.idToken);
                    auth.setRefresh(res.data.refreshToken);
                    apiCalls.recordLogin({'owner': res.data.localId, 'platform': 'W'})
                    .catch(err => console.log(err));
                    router.push('/app/projects');
                } else {
                    await dispatch('verifyEmail', res.data.idToken)
                }
            } catch { return }
        },
        async signUp({ dispatch }, payload) {
            try {
                let res = await authCalls.signUp(payload);
                auth.setEmail(res.data.email);
                auth.setOwner(res.data.localId);
                auth.setToken(res.data.idToken);
                auth.setRefresh(res.data.refreshToken);
                await apiCalls.addUser({'userId': auth.getOwner(), 'email': auth.getEmail()});
                await dispatch('verifyEmail', auth.getToken());
                router.push('/auth/login');
            } catch (err) {
                console.log(err)
            }    
        },
        async resetPassword({ dispatch }, payload) {
            if (!payload) { 
                dispatch('callToast', "Error: Invalid email address.")
            } else { 
                await authCalls.resetPassword(payload);
                dispatch('callToast', "Email sent. Follow link to reset password.");
                router.push('/auth/login');
            }
        },
        async refreshToken() {
            let payload = {
                'grant_type': 'refresh_token',
                'refresh_token': auth.getRefresh()
            };
            try {
                let res = await authCalls.refresh(payload)
                // note snake_case instead of camelCase! (Why GCP?)
                auth.setToken(res.data.id_token);
                auth.setRefresh(res.data.refresh_token);
            } catch(err) {
                console.log('Token refresh error: ', err)
            }
        },
        async logout({ commit, dispatch }) {
            try {
                auth.clear();
                commit('clear'); // this is important!
                // dispatch('callToast', 'Signed out');
            } catch (err) {
                dispatch('callToast', 'Error: Failed to logout')
            }
    
        },
        async isEmailVerified({}, payload) {  // eslint-disable-line
            let res = await authCalls.getUserData(payload);
            return res.data.users[0].emailVerified;
        },
        async verifyEmail({dispatch}, payload) {
            await authCalls.verifyEmail(payload);
            dispatch('callToast', 'Confirmation email sent. Please confirm to login.');
        },
        async verifyCaptcha(payload) { // eslint-disable-line
            await authCalls.verifyCaptcha(payload)
        }, 
    }

    
}


<template>
    <div class="safe-switch-wrapper">
        <label class="safe-switch" for="safe-checkbox">
            <input 
                type="checkbox" 
                id="safe-checkbox" 
                v-model="selected"
                @change="handleChange"
            />
            <div class="slider"></div>
        </label>
    </div>
    <popup v-if="attemptUnlock" @close="closeDialogue">
        <password-confirm @close="closeDialogue" @verified="unlocked"/>
    </popup>
</template>

<script>
import Popup from '@/components/common/Popup.vue'
import PasswordConfirm from './PasswordConfirm.vue'

export default {
    components: {
        Popup, PasswordConfirm
    },
    data() {
        return {
            selected: null,
            attemptUnlock: false
        }
    },
    methods: {
        handleChange() {
            if (this.isSafe) {
                this.selected = true;
                this.attemptUnlock = true;
            } else {
                this.$store.commit('setSafe', true);
                this.selected = true;
            }
        },
        closeDialogue() {
            this.attemptUnlock = false;
        },
        unlocked() {
            this.$store.commit('setSafe', false);
            this.selected = false;
        },
        failed() {
            this.selected = true;
        }
    },
    mounted() {
        if(this.isSafe) {
            this.selected = true;
        } else {
            this.selected = false;
        }
    },
    computed: {
        isSafe() {
            return this.$store.state.uiStore.isSafe;
        }
    }
}
</script>

<style scoped lang="stylus">
div.safe-switch-wrapper 
    display: flex;
    align-items: center;

    label.safe-switch 
        display: inline-block;
        height: 34px;
        position: relative;
        width: 60px;


        input 
            display:none;


        div.slider 
            background-color: var(--accent)
            bottom: 0;
            cursor: pointer;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: .4s;
            border-radius: 7px;

            &:before 
                background-color: #fff;
                bottom: 4px;
                content: "";
                height: 26px;
                left: 4px;
                position: absolute;
                transition: .4s;
                width: 26px;
                border-radius: 35%;

        input:checked + .slider:before 
            transform: translateX(26px);


@media screen and (max-width: 1000px) 
    div.safe-switch-wrapper 
        label.safe-switch 
            height: 28px;
            width: 50px;
            div.slider 
                &:before 
                    bottom: 5px;
                    height: 18px;
                    left: 5px;
                    width: 18px;
            input:checked + .slider:before 
                transform: translateX(22px);

</style>
<template>
        <div 
            @keydown.esc="close" 
            class="toast-container up" 
            :class="{'red': msg.toLowerCase().includes('error')}"
        >
            <h2>{{msg}}</h2>
            <button  @keydown.esc="close" @click="close" type='button' class="close" alt="close network message popup">
                <img src="@/assets/img/close.svg" alt="close icon">
            </button>
        </div>
</template>

<script>

export default {
    props: ['offset'],
    methods: {
        close() {
            this.$store.commit('setIsNewMsg', false)
        }
    },
    computed: {
        msg() {
            return this.$store.state.uiStore.networkMsg
        },
        isToast() {
            return this.$store.state.uiStore.isNewMsg
        }
            
    }   
}
</script>

<style lang="stylus" scoped>


    div.toast-container
        display block
        max-width 30rem
        padding 0.5rem 3rem 0.5rem 3rem
        border-radius 7px
        background-color var(--toast-success)
        font-size 1.25rem
        font-family var(--font)
        color var(--text)
        position fixed
        left 50%
        transform translateX(-50%)
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
        text-align center
        z-index 100

        button.close 
            border none
            background none
            position absolute
            top 0 
            right 0

            img 
                width 2rem

                &:hover
                    transform scale(1.2)
                    cursor pointer

    div.toast-container.red 
        background var(--warn)

    div.up
        bottom 1rem
        animation toast-up
        animation-duration .4s
        animation-iteration-count 1
        animation-timing-function ease-out

@keyframes toast-up
    0% {bottom: -1rem; opacity: 0;}
    100% {bottom: 1rem; opacity: 1;}

@keyframes toast-down
    0% {bottom: 1rem; opacity: 1;}
    100% {bottom: -1rem; opacity: 0;}
    
    
@media screen and (max-width: 1000px) 
    div.toast-container
        max-width 25rem
        font-size 1rem
        button.close 
            img 
                width 1.75rem
        

@media screen and (max-width: 600px) 
    div.toast-container
        max-width 20rem
        font-size 0.75rem
        padding 0.25rem 2rem 0.25rem 2rem
        button.close 
            img 
                width 1.25rem

</style>
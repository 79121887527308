<template>
    <ul v-if="active" class="dropdown-content">
        <li class="email">{{ email }}</li>
        <li class="account" @click="goToAccount">
            <img src="@/assets/img/manage-account.svg" alt="">
            <p>Manage Account</p>
        </li>
        <li class="logout" @click="logoutUser">
            <img src="@/assets/img/logout.svg" alt="">
            <p>Logout</p>
        </li>
    </ul>
</template>

<script>
import auth from '@/middleware/auth'
export default {
    inject: ['sharedState'],
    methods: {
        async logoutUser() {
            await this.$store.dispatch("logout")
            this.$router.push('/auth/login')
        },
        goToAccount() {
            this.sharedState.active = false;
            this.$router.push('/app/account');
        }
    },
    computed: {
        active () {
            return this.sharedState.active
        },
        email() {
            return auth.getEmail()
        }
    }
}
</script>

<style lang="stylus" scoped>
    ul.dropdown-content 
        position fixed
        top 5.75rem
        right 0
        list-style-type: none;
        padding: 0;
        margin: 0;
        z-index: 1;


        li 
            padding 1rem
            font-family var(--font)
            font-size 1.5rem
            background-color: var(--dropdown)
            opacity: 0
            display flex

            &:hover
                cursor: pointer;
                background var(--dropdown-hover)

            a 
                text-decoration: none
                color var(--text)

            button 
                display flex
                align-items center

            p 
                margin 0 0 0 0.5rem
                padding 0
             
            img 
                width 1.75rem
                height 1.75rem

        li.email
            border-top-left-radius 7px
            background-color: var(--dropdown-heading)
            font-weight bold

            &:hover
                cursor: default
                background-color: var(--dropdown-heading)

        // li 
        //     display flex

        //     button
        //         color var(--title)
        //         background var(--charcoal)
        //         border none
        //         padding .6rem
        //         border-radius 7px
        //         font-family var(--font)
        //         font-size 1.5rem
        //         display flex
        //         align-items: center

        //         img 
        //             height 1.5rem
        //             margin-left .4rem

        //         &:hover 
        //             background var(--accent)
        //             cursor: pointer;

        //     p
        //         margin 0
        //         margin-left 0.5rem
        //         padding 0
        //     img 
        //         width 2rem
        
        li.account  
            img
                filter: invert(90%)

        li.logout 
            border-bottom-left-radius 7px

    ul.dropdown-content
        .email  
            transform-origin: top center
            animation: translateX 300ms 60ms ease-in-out forwards
        .account  
            transform-origin: top center
            animation: translateX 300ms 120ms ease-in-out forwards
        .billing  
            transform-origin: top center
            animation: translateX 300ms 180ms ease-in-out forwards
        .logout  
            transform-origin: top center
            animation: translateX 300ms 240ms ease-in-out forwards


@keyframes translateX {
    0% {
        opacity: 0;
        transform: translateX(64px);
    }
    
    80% {
        transform: translateX(-5px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@media screen and (max-width: 1000px)
    ul.dropdown-content
        top 4.5rem
        li 
            font-size 1.25rem
            img 
                width 1.5rem
                height 1.5rem
            

@media screen and (max-width: 600px)
    ul.dropdown-content
        top 4.25rem
        li 
            font-size 1rem
            padding 0.75rem
            img 
                width 1.25rem
                height 1.25rem
            
</style>
<template>
    <form @keydown.enter.prevent="submit">
        <p>Hold up – you are about to delete project</p>
        <h1>{{this.project.name}}</h1>
        <p>This action is permanent and cannot be undone. To proceed, please type <span>{{this.project.name}}</span> below.</p>
        <div class="text-input">
            <input type="text" v-model="text">
        </div>
        <action-button
            button-text="Cancel"
            button-type="cancel"
            @click="closeDialog"
        />
        <action-button
            v-if="text.toLowerCase() == this.project.name.toLowerCase()"
            button-text="Delete"
            button-type="warn"
            @click="submit"
            class="submit-btn"
        />
    </form>
</template>

<script>
import ActionButton from '@/components/auth/ActionButton.vue'
export default {
    props: ['project'],
    components: {
        ActionButton
    },
    data() {
        return {
            text: '',
        }
    },
    methods: {
        async submit() {
            if(this.text.toLowerCase() !== this.project.name.toLowerCase()) {
                this.$store.dispatch('callToast', "Error: Text must match.")
                return;
            } else {
                try {
                    this.closeDialog()
                    let id = this.project.id;
                    await this.$store.dispatch('deleteProject', id);
                    await this.$store.dispatch('fetchProjects');
                    if (!this.$store.getters.hasProjects) {
                        this.$router.push('create-project')
                    }
                } catch {
                    // TODO
                }
            } 
            
        },
        closeDialog() {
            this.$emit("close")
        },
    }
}
</script>

<style lang="stylus" scoped>

    form
        text-align: center
        margin-bottom 1rem

        h1
            font-size 2rem

        p 
            font-size 1.5rem

            span 
                font-family monospace
                font-weight bold
        
        div.text-input
            margin 3rem 0 1rem 0

            input 
                font-size: 1.5rem
                border-radius: 5px
                width 16rem
                padding: 0.25rem
                background-color: hsla(0, 0%, 100%, 0.2)
                color: var(--text)
                text-align: center

        button.submit-btn
            margin-left 2rem
            
@media all and (max-width: 1000px)
    form
        margin-bottom 0.75rem

        h1
            font-size 1.75rem

        p 
            font-size 1.25rem

        div.text-input
            margin 2rem 0 1rem 0
            input 
                font-size 1.25rem
                width 13rem
        button.submit-btn
            margin-left 1.5rem

@media all and (max-width: 600px)
    form
        margin-bottom 0.5rem

        h1
            font-size 1.5rem

        p 
            font-size 1rem

        div.text-input
            input 
                font-size 1rem
                width 10rem
        button.submit-btn
            margin-left 1rem
    
</style>
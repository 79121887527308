<template>
    <div class="base">
        <spinner bg='panel' v-if="loading"/>
        <popup v-if="showPaywall">
            <paywall/>
        </popup>
        <Nav v-if='pageWithNav'/>
        <side-bar v-if='pageWithNav'/>
        <main-panel>
            <router-view></router-view>
        </main-panel>
    </div>
</template>

<script>
import Nav from '@/components/common/Nav'
import Paywall from '@/components/common/Paywall'
import SideBar from '@/components/common/SideBar'
import MainPanel from '@/components/common/MainPanel'
import Popup from '@/components/common/Popup'
export default {
    components: { Nav, Paywall, SideBar, MainPanel, Popup },
    computed: {
        pageWithNav() {
            if ( this.$route.name === 'code' || this.$route.name === 'random-codes' ) {
                return false
            } else {
                return true
            }
        },
        loading() {
            return this.$store.state.uiStore.loading
        },
        showPaywall() {
            return this.$store.state.uiStore.showPaywall
        }
    },
}

</script>

<style lang="stylus" scoped>
div.base
    display: flex
    flex-direction: column
    flex-grow: 1
    height 100%
</style>
<template>
    <div class="panel">
        <router-link to="/app/add-subject"   
            class="add-subject-link"
        >    
            <img src="@/assets/img/add-subject.svg">
            <span class="button-text">Add Subject</span>
        </router-link>
    </div>
</template>

<style lang="stylus" scoped>
div.panel

    position fixed
    bottom 1rem
    right 1rem
    padding 1rem
    border-radius: 7px

    a.add-subject-link
        display flex
        flex-flow row
        align-items center
        text-align center
        padding 1rem
        background var(--panel)
        border-radius 7px
        text-decoration none 
        color var(--text)
        font-family var(--font)
        font-size 1.5rem
        grid-row-start: 2
        grid-column-start 3
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        
        &:hover
            cursor pointer 
            transform scale(1.05)
            background-color var(--brighter-panel)

        img 
            margin-right .5rem
            height 2rem
            filter var(--svg-filter)

@media screen and (max-width: 1200px)
    span.button-text 
        display: none
    div.panel 
        a.add-subject-link
            img 
                margin-right 0

@media screen and (max-width: 600px)
    div.panel
        padding 0
        a.add-subject-link
            padding 0.75rem
            img 
                height 1.5rem
                margin-right 0
</style>
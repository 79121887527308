import PageNotFound from '@/pages/auth/PageNotFound.vue'
import appRoutes from './appRoutes'
import siteRoutes from './siteRoutes'
import authRoutes from './authRoutes'
import AuthLayout from '@/components/layouts/AuthLayout'
import SiteLayout from '@/components/layouts/SiteLayout'
import AppLayout from '@/components/layouts/AppLayout'

export default [

    {   
        path: '/:pathMatch(.*)*', 
        component: PageNotFound,
        name: 'not-found'
    },
    {
        path: '/auth',
        component: AuthLayout,
        children: authRoutes,
    },
    {
        path: '/',
        component: SiteLayout,
        children: siteRoutes,
    },
    {
        path: '/app',
        component: AppLayout,
        children: appRoutes,
    },

]






<template>
    <div>
        <a class="anchor" id="about"></a>

        <div class="logo-section">
            <logo/>
            <name-logo class="name-logo"/>
        </div>
        <hr>

        <div class="description-section">
            <h2>Making volume photography less painful</h2>
            <p>
                Large photoshoots can contain hundreds or even thousands of subjects, 
                and manually matching up the information beween each subject and their 
                images is a tedious and error-prone process. That's where ShutterSort comes in. We 
                automate the process of matching subject images to their information, and 
                allow you to sort your photo shoot into a directory structure of your choice.
            </p>
            <p>
                Our design philosphy is centered around <span>flexibility</span>. We don't 
                force you to use a particular storage solution or route orders through any 
                particular processing service. Instead, we focus on providing the most ergonomic 
                and intuitive process for helping you to get through your high volume photo 
                shoots. And at the end of your photo shoot, we make it incredibly simple to sort 
                your images into a structure that fits your needs.
            </p>
            <button @click="this.$router.push('/explain')">See how it works</button>
        </div>
    </div>
</template>

<script>
import NameLogo from '@/components/site/NameLogo.vue'
import Logo from '@/components/common/Logo.vue'
export default {
    components: {
        NameLogo,
        Logo
    }
}
</script>

<style scoped lang="stylus">
    #about 
        margin-top: 1rem;
        
    span
        font-weight bold
        // text-decoration: underline
    
    h1.name-logo.logo
        margin-top: 2rem
        margin-bottom: 1rem

    #about
        top: -20rem
    
    @keyframes spin 
        0% { transform: rotate(0deg) }
        100% { transform: rotate(360deg) }

    button 
        padding: 0.75rem;
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        color: rgb(200, 200, 200);
        font-weight: bold;
        font-size: 1.5rem;
        background-color: var(--accent);
        border-radius: 0.25rem;
        border: none;
        margin 1rem 0 1rem 0
        font-family var(--font)
    
        &:hover 
            transform: scale(1.06)
            cursor: pointer;

@media all and (max-width: 600px)
    button 
        font-size 1.1rem
</style>
<template>
    <label class="checkbox path"> 
        <input type="checkbox" @change="checkIt" :class="{disabled: isSafe}" :disabled="isSafe" v-model="quitAsking">
        <svg viewBox="0 0 21 21">
            <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
        </svg>
    </label>

</template>

<script>
export default {
    data() {
        return {
            quitAsking: null
        }
    },
    methods: {
        async checkIt() {
            this.$store.commit('setQuitAsking', this.quitAsking);
        }
    },
    computed: {
        isSafe() {
            return this.$store.state.uiStore.isSafe;
        }
        
    },
    mounted() {
        this.quitAsking = this.$store.state.uiStore.quitAsking;
    }
}
</script>

<style lang="stylus" scoped>

    .checkbox 
        --border: var(--text)
        --border-hover: var(--text)
        --border-active: var(--text)
        --tick: var(--brightest-panel)
        position: relative;
        
        input,svg 
            width: 1.6rem
            height: 1.6rem
            display: block;
    
        input 
            -webkit-appearance: none;
            -moz-appearance: none;
            position: relative;
            outline: none;
            background: var(--background);
            border: none;
            margin: 0;
            padding: 0;
            cursor: pointer;
            border-radius: 7px;
            transition: box-shadow .3s;
            box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
            &:hover 
                --s: 2px;
                --b: var(--border-hover);
            
            &:checked 
                --b: var(--border-active);
        
        input.disabled
            --b var(--greyed-out)
            &:hover
                pointer-events: none
                --b: var(--greyed-out)
    
        svg 
            pointer-events: none;
            fill: none;
            stroke-width: 2px;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke: var(--stroke, var(--border-active));
            position: absolute;
            top: 0
            left: 0
            width: 1.6rem
            height: 1.6rem
            transform: scale(var(--scale, 1)) translateZ(0);
    
        &.path 
            input 
                &:checked 
                    --s: 2px;
                    transition-delay: .4s;
                    & + svg 
                        --a: 16.1 86.12;
                        --o: 102.22;
                    
                
            
            svg 
                stroke-dasharray: var(--a, 86.12);
                stroke-dashoffset: var(--o, 86.12);
                transition: stroke-dasharray .6s, stroke-dashoffset .6s;
        
    
    
@media screen and (max-width: 1000px) 
    .checkbox 
        input, svg
            width: 1.4rem
            height: 1.4rem
            

@media screen and (max-width: 600px) 
    .checkbox 
        input, svg
            width: 1.2rem
            height: 1.2rem


</style>
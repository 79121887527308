export default {
    setActiveSubjects(state, subjects) {
        if (subjects instanceof Array) {
            let sorted = subjects.sort((a, b) => a.lastName.localeCompare(b.lastName));
            state.activeSubjects = sorted;
        }
    },
    setSubjectToEdit(state, id) {
        let subject = state.activeSubjects.find(s => s.id == id);
        state.subjectToEdit = subject;
    },
    setActiveProject(state, project) {
        state.activeProject = project;
    },
    setProjects(state, projects) {
        if (projects instanceof Array) {
            state.projects = projects;
        }
    },
    setListLimit(state, limit) {
        state.listLimit = limit;
    },
    clear(state) {
        state.projects = [],
        state.listLimit = 100,
        state.subjectToEdit = {},
        state.activeSubjects = [],
        state.activeProject = {},
        state.uiStore.quitAsking = false,
        state.uiStore.isSafe = false
    }
}
<template>
    <div class="page-container" ref="pageContainer">

        <a ref="back" alt="go back to previous page" @click="goBack()">
            <img src="@/assets/img/arrow.svg"/>
        </a>  

        <div ref="infoSection" class="info-section">
            <h1 class="info-label">First Name: {{truncate(subject.firstName)}}</h1>
            <h1 class="info-label">Last Name: {{truncate(subject.lastName)}}</h1>
            <h1 class="info-label" v-for="(attribute, key, i) in groups" :key="i">{{key}}: {{truncate(attribute)}}</h1>
        </div>

        <div class="code-section" ref="codeSection">
            <div class="pacman-border">
                <img ref="topLeft" src="@/assets/img/pacman.svg" alt="" class="top-left">
                <img ref="topRight" src="@/assets/img/pacman.svg" alt="" class="top-right">
                <img ref="bottomLeft" src="@/assets/img/pacman.svg" alt="" class="bot-left">
                <img ref="bottomRight" src="@/assets/img/pacman.svg" alt="" class="bot-right">

                <table class="table" ref="table">
                    <tr v-for="(row, i) in codeRows" :key=i>
                        <td class="table-elem" v-for="(symbol, j) in row" :key=j>
                            <span>{{symbol}}</span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
    computed: {
        codeRows() {
            const chars = Array.from(this.subject.code);
            const rows = [];
            for (var i = 0; i < 8; i++) {
                rows.push(chars.slice(i * 8, (i + 1) * 8));
            }
            return rows;
        },
        subject() {
            return this.$store.getters.getSubject(this.$route.params.id)
        },
        groups() {
            let groups = this.subject.groups
            let sorted = Object.keys(groups).sort()
            let sortedObj = {}
            sorted.forEach((x) => {
                let key = x.charAt(0).toUpperCase() + x.slice(1)
                sortedObj[key] = groups[x]
            })                
            return sortedObj
        }
    },

    methods: {
        goBack() {
            this.$router.back()
        },

        truncate(text) {
            text = String(text);
            if (text.length <= 16) {
                return text;
            } else {
                return text.substring(0, 13) + "...";
            }   
        },

        resizeComponents() {
            const windowHeight = window.innerHeight;
            this.$refs.pageContainer.style.height = windowHeight.toString() + "px";
            
            const codeHeight = this.$refs.codeSection.clientHeight;
            const codeWidth = this.$refs.codeSection.clientWidth;
            const tableDim = (codeWidth > codeHeight) ? codeHeight * 0.82 : codeWidth * 0.82;
            this.$refs.table.style.height = tableDim.toString() + "px";
            this.$refs.table.style.width = tableDim.toString() + "px";
            const tableFontSize = tableDim * 0.085;
            this.$refs.table.style.fontSize = tableFontSize.toString() + "px";
            const infoFontSize = Math.min(tableFontSize * 0.35, 24);
            this.$refs.infoSection.style.fontSize = infoFontSize.toString() + "px";
            const backSize = tableFontSize;
            this.$refs.back.style.height = backSize.toString() + "px";
            this.$refs.back.style.width = backSize.toString() + "px";
            const imageSize = tableDim * 0.11;
            const imageOffset = imageSize * (-0.5);
            this.$refs.topLeft.style.width = imageSize.toString() + "px";
            this.$refs.topLeft.style.top = imageOffset.toString() + "px";
            this.$refs.topLeft.style.left = imageOffset.toString() + "px";
            this.$refs.topRight.style.width = imageSize.toString() + "px";
            this.$refs.topRight.style.top = imageOffset.toString() + "px";
            this.$refs.topRight.style.right = imageOffset.toString() + "px";
            this.$refs.bottomLeft.style.width = imageSize.toString() + "px";
            this.$refs.bottomLeft.style.bottom = (imageOffset - tableFontSize * 0.2).toString() + "px";
            this.$refs.bottomLeft.style.left = imageOffset.toString() + "px";
            this.$refs.bottomRight.style.width = imageSize.toString() + "px";
            this.$refs.bottomRight.style.bottom = (imageOffset - tableFontSize * 0.2).toString() + "px";
            this.$refs.bottomRight.style.right = imageOffset.toString() + "px";
        },
    },

    mounted() {
        window.addEventListener("resize", this.resizeComponents);
        this.resizeComponents();
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.resizeComponents);
    }
    
}
</script>
<style scoped lang="stylus">
    div.page-container
        background white
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        display: flex;

        a 
            position: absolute;
            border: none;
            background: none;
            outline: none;
            z-index 1
            transform rotate(180deg)
            top 1rem
            left 1rem

            img 
                filter brightness(0)

            &:hover 
                transform: scale(1.2) rotate(180deg)
                cursor: pointer 
    
        div.info-section 
            font-family: 'noto_monoregular';
            color: black;
            overflow: hidden;
            display: flex;
            flex-flow column
            h1.info-label
                margin 0
                padding 0.5vmin
    
        div.code-section
            display: flex;
            justify-content: center
            align-items: center
            flex-grow: 1

            div.pacman-border 
                position relative
                display inline-block

                .top-left
                    transform rotate(180deg)
                    position absolute
                .top-right
                    transform rotate(270deg)
                    position absolute
                .bot-left
                    transform rotate(90deg)
                    position absolute
                .bot-right
                    position absolute
                
                table.table 
                    position inline-block
                    table-layout fixed
                    font-family: 'noto_monoregular'
                    font-weight: bold;
                    color: black;
                    background-color white
                    margin 0
        
                    td.table-elem
                        text-align: center
                        padding 0
                        margin 0



@media (orientation: landscape)
    div.page-container 
        flex-flow row
        div.info-section
            justify-content: center
            align-items: flex-start
            width 35%
            h1.info-label
                margin 1rem
                margin-bottom: 1vmin
                margin-left 10%
        div.code-section
            div.pacman-border 
                margin-right: 1rem


        

@media (orientation: portrait)
    div.page-container 
        flex-flow column
        div.info-section
            justify-content: center
            height 30%
            text-align: center
            align-items: center
            margin-top clamp(5rem, 7vmin, 7vmin)
            h1.info-label
                margin 0 0 0.5vmin 0
        div.code-section
            div.pacman-border 
                margin-bottom: 1rem


</style>

<template>
<div class="master-container" ref="masterContainer">

	<router-view v-slot="slotProps">
		<transition name='route' mode="out-in">
			<component :is="slotProps.Component" class="page"></component>
		</transition>
	</router-view>

    <toast v-if="newMsg"/>

</div>
</template>

<script>

export default {

    computed: {

        newMsg() {
            return this.$store.state.uiStore.isNewMsg
        },

        page() {
            if ( this.$route.path === '/auth/login' ) {
                return "login"
            } else {
                return "panel"
            }
        },

    },

    created() {
        const currentTheme = sessionStorage.getItem('theme') ? sessionStorage.getItem('theme') : null;

        if (currentTheme) {
            document.documentElement.setAttribute('data-theme', currentTheme);
        }
        
        // this.$store.dispatch('watchSession')
    },
};
</script>

<style lang='stylus'>
@font-face {
    font-family: 'noto_monoregular';
    src: url('./assets/fonts/notomono-regular-webfont.woff2') format('woff2'),
        url('./assets/fonts/notomono-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family:"bc-alphapipe";
    src:url("https://use.typekit.net/af/93ff25/00000000000000003b9b3093/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/93ff25/00000000000000003b9b3093/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/93ff25/00000000000000003b9b3093/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
}
@font-face {
    font-family:"protipo";
    src:url("https://use.typekit.net/af/486ae3/00000000000000003b9b48c4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/486ae3/00000000000000003b9b48c4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/486ae3/00000000000000003b9b48c4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
}

div.master-container
    // background-color: red
    height 100%


:root
    // COLORS
    --bg: hsla(264, 49%, 11%, 1)
    --bg-loading: hsla(264, 49%, 9%, 1)
    --bg-opacity : hsla(264, 41%, 25%, 1)
    --modal-bg: hsla(0, 0, 0, 0.3)
    --modal-bg-dark: hsla(0, 0, 0, 0.8)
    --text: hsla(0, 0%, 80%, 1)
    --title: hsla(0, 0%, 90%, 1)
    --panel: hsla(0, 0%, 100%, 6%)
    --panel-solid: hsla(190, 50%, 30%, 1)
    --main-panel: hsla(266, 44%, 14%, 1)
    --brighter-panel: hsla(0, 0%, 100%, 5%)
    --brightest-panel: hsla(0, 0%, 100%, 20%)
    --border: hsla(0, 0%, 86%, .5)
    --accent: hsla(243, 40%, 45%, 1)
    --charcoal: hsla(266, 6%, 23%, 1)
    --warn: hsla(325 71% 38% 1)
    --svg-filter: brightness(1)
    --greyed-out: hsla(0, 0%, 100%, 1%)
    --greyed-out-text: hsla(0, 0%, 30%, 90%)
    --greyed-out-opaque: hsla(0, 0%, 40%, 100%)
    --slight-grey-text: hsla(0, 0%, 100%, 40%)
    --link-text: hsla(280, 55, 55, 100%)
    --greyed-svg: brightness(.4)
    --toast-success: hsla(116 70% 30% 1)
    --dropdown-heading: hsl(240, 25%, 33%)
    --dropdown: hsl(240, 25%, 40%)
    --dropdown-hover: hsl(240, 25%, 45%)
    --solid-panel:hsl(270, 30%, 16%)

    // OTHER
    --title-font: bc-alphapipe, sans-serif 
    --font: 'Protipo', 'Montserrat', 'Open Sans'

[data-theme="light"]
    --bg: hsla(264, 49%, 91%, 90%)
    --bg-opacity : hsla(264, 41%, 91%, 80%)
    --text: hsla(0, 0%, 7%, .93)
    --title: hsla(0, 0%, 8%, 1)
    --panel: hsla(0, 0%, 10%, 10%)
    --panel-solid: hsla(30, 60%, 80%, 1)
    --brighter-panel: hsla(0, 0%, 20%, 20%)
    --brightest-panel: hsla(0, 0%, 30%, 35%)
    --border: hsla(0, 0%, 7%, .8)
    --accent: hsla(360, 100%, 75%, 0.64) 
    --charcoal: hsla(360, 100%, 94%, 0.64)
    --warn: hsla(0, 100%, 60%, 1)
    --svg-filter: invert(0.4) sepia(0) saturate(1) hue-rotate(0deg) brightness(0.5)
    --greyed-out: hsla(0, 0%, 10%, 3%)
    --greyed-out-text: hsla(0, 0%, 7%, .33)
    --greyed-svg: brightness(.8)
    --toast-success: hsla(110 70% 70% 1)

html 
    box-sizing: border-box;
    overflow-y auto

*, *:before, *:after {
    box-sizing: border-box;
}

body
    margin 0
    background: var(--bg)

.route-enter-from
    opacity 0

.route-leave-to
    opacity 0

.route-enter-active
    transition all .2s ease-out
.route-leave-active
    transition all .2s ease

.route-enter-to,
.route-leave-from 
    opacity 1

// .page 
//     visibility hidden

* {
  scrollbar-width: 12px;
  scrollbar-color: var(--charcoal) var(--bg);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--bg);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--charcoal);
  border-radius: 20px;
  border: 3px solid var(--bg);
}

</style>

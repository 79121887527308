import auth from '@/middleware/auth'
import AccountPage from '@/pages/app/AccountPage'
import CreateProjectPage from '@/pages/app/CreateProjectPage'
import FindSubjectPage from '@/pages/app/FindSubjectPage'
import CodePage from '@/pages/app/CodePage'
import ProjectsPage from '@/pages/app/ProjectsPage'
import ManageProjectsPage from '@/pages/app/ManageProjectsPage'
import SettingsPage from '@/pages/app/SettingsPage'
import AddSubjectPage from '@/pages/app/AddSubjectPage'
import SelectColumnsPage from '@/pages/app/SelectColumnsPage'
import EditSubjectPage from '@/pages/app/EditSubjectPage'
import ManualCreatePage from '@/pages/app/ManualCreatePage'
import RandomCodePage from '@/pages/app/RandomCodePage'

export default [
    {
        path: 'account',
        component: AccountPage,
        beforeEnter: auth.authenticate
    },
    {
        path: 'create-project', 
        component: CreateProjectPage, 
        beforeEnter: auth.authenticate
    },
    { 
        path: 'code/:id', 
        name: 'code', 
        component: CodePage, 
        props: true, 
        beforeEnter: auth.authenticate
    },
    {
        path: 'projects', 
        name: 'projects',
        component: ProjectsPage, 
        beforeEnter: auth.authenticate
    },
    {
        path: 'manage', 
        component: ManageProjectsPage, 
        beforeEnter: auth.authenticate
    },
    {
        path: 'settings', 
        component: SettingsPage, 
        beforeEnter: auth.authenticate
    },
    {
        path: 'find-subjects/:id',
        name: 'find-subjects',
        component: FindSubjectPage, 
        props: true,
        beforeEnter: auth.authenticate
    },
    {
        path: 'edit-subject/:id',
        name: 'edit-subject',
        component: EditSubjectPage, 
        props: true,
        beforeEnter: auth.authenticate
    },
    {
        path: 'add-subject', 
        component: AddSubjectPage, 
        beforeEnter: auth.authenticate
    },
    {
        path: 'table', 
        component: SelectColumnsPage, 
        beforeEnter: auth.authenticate
    },
    {
        path: 'create-empty-project', 
        component: ManualCreatePage, 
        beforeEnter: auth.authenticate
    },
    {
        path: 'random-codes',
        name: 'random-codes',
        component: RandomCodePage,
        beforeEnter: auth.authenticate
    }
]
<template>
    <div class="background" :class="bg">
        <div class="foreground">
            <img v-if="isDark" src="@/assets/img/blue.svg" alt="logo">
            <img v-else src="@/assets/img/magenta-logo.svg" alt="logo">
        </div>
    </div>
</template>

<script>
export default {

    props: ['bg'],

    computed: {

        isDark() {
            let bool
            let theme = localStorage.getItem('theme')
            if (theme === "dark" || !theme) {
                bool = true
            } else { bool = false}
            return bool
        }
        
    },

}
</script>

<style scoped lang='stylus'>

    div.background
        background-color var(--bg-loading)
        position: fixed;
        display: flex;
        align-items: center;

    div.panel
        top 6.5rem
        left 20rem
        width calc(100vw - 20rem)
        height calc(100vh - 6.5rem)
        z-index: 1;
        border-top-left-radius 10px;

        div.foreground
            margin-left: auto;
            margin-right: auto;
            margin-bottom 25%
            

    div.background.login 
        display block
        position relative
        bottom auto
        right auto
        background none

        
        div.foreground
            margin auto 0
    
    div.background.sign-up
        display flex
        position relative
        background none

        div.foreground
            margin 3vmin auto 3vmin auto
        

    img 
        width: 12rem;
        height: 12rem;
        animation spin 
        animation-duration 8s
        animation-iteration-count infinite
        animation-timing-function linear

    @media screen and (max-width: 1000px)  
        div.panel 
            position fixed
            top 10rem
            left 0
            border-radius 10px 10px 0 0
            width 100%
            overflow-y auto

    @media screen and (max-width: 800px) 
        div.panel 
            top 9.5rem

    @media screen and (max-width: 600px) 
        div.panel 
            top 9rem
        img 
            width 10rem
            height 10rem


    @keyframes spin 
        0%  
            transform: rotate(0deg) 
        100%  
            transform: rotate(720deg) 


    @keyframes background-transition 
        0%  
            background-position 1% 0% 
        50%  
            background-position 99% 100% 
        100%  
            background-position 1% 0% 

</style>
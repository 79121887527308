<template>
    <div class="panel">
        <h1>Desktop Client Downloads</h1>
        <hr>
        <p>The ShutterSort desktop client allows you to efficiently sort photos from your projects. Once you've created an account, download the version that corresponds with your operating system to start sorting.</p>
        <div class="container">
            <div class="tabs">
                <button id="windows" v-bind:class="{active: selected == 'windows'}" @click="setSelected('windows')">Windows</button>
                <button v-bind:class="{active: selected == 'macos'}" @click="setSelected('macos')">Mac</button>
                <button v-bind:class="{active: selected == 'linux'}" @click="setSelected('linux')">Linux</button>
            </div>
            <div class="info" v-show="selected == 'windows'">
                <div class="link-div"><a href="">win-x86-64</a></div>
            </div>
            <div class="info" v-show="selected == 'macos'">
                <div class="link-div"><a href="">mac-x86-64</a></div>
                <div class="link-div"><a href="">mac-arm-64</a></div> 
            </div>
            <div class="info" v-show="selected == 'linux'">
                <h2>Ubuntu/Debian Based Distros</h2>
                <pre><code>
# Add GPG Key
sudo wget -q -O - https://packages.shuttersort.io/deb/KEY.gpg | apt-key add -
    
# Add ShutterSort repo to APT
sudo echo "deb https://packages.shuttersort.io/deb/ /" > /etc/apt/sources.list.d/shuttersort.list
    
# Update and install
sudo apt update
sudo apt install shuttersort
                </code></pre>

                    <br>
                    <h2>Fedora/RHEL/RPM Based Distros</h2>
                <pre><code>
#### Place the following in /etc/yum.repos.d/shuttersort.repo ###

[shuttersort]
name=ShutterSort
baseurl=https://packages.shuttersort.io/rpm/amd64
enabled=1
gpgcheck=1
gpgkey=https://packages.shuttersort.io/KEY.gpg
                </code></pre>
                <br>
                <pre><code>
# Update and install
sudo dnf update
sudo dnf install shuttersort
                </code></pre>
            </div>
        </div>
        <!-- <div class="spacer"></div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            selected: 'windows',
        }
    },
    methods: {
        setSelected(platform) {
            this.selected = platform;
        }
    }
}
</script>

<style scoped lang="stylus">
    div.panel
        margin auto
        margin-top 8rem
        span 
            font-size 1.25rem
            font-family: 'monospace'
            background-color: var(--bg)
            padding 0.2rem
            border-radius: 3px
        a
            font-size 1.25rem
            color var(--link-text)

        h1 
            font-size: 2.25rem;
            margin: 1rem;
            font-weight 400

        hr 
            width 60%
            margin: auto;
            color: rgb(72, 61, 165);
            border: 2px solid;
            border-radius: 1px;

        // background-color: var(--main-panel)
        width: 80%
        max-width: 50rem;
        padding 3rem
        padding-top 1rem
        border-radius: 1em

        pre 
            page-break-inside: avoid
            font-font-family: monospace
            font-size 1rem
            max-width: 90%
            overflow: auto
            display: block
            text-align: left
            background-color: hsl(0, 0%, 18%)
            padding 1rem
            margin: auto
            border-radius: 5px

        p 
            font-size 1.3rem
            margin-top 2rem
            margin-bottom: 0            

        div.container
            overflow-y: auto
            margin-top 2rem
            background-color var(--solid-panel)
            border-radius 7px
            div.tabs
                display: flex
                margin 0
                padding 0
                button
                    font-size 1.6rem
                    font-weight normal
                    margin 0
                    color var(--text)
                    background: none
                    border none
                    padding 1rem 2rem 1rem 2rem
                button:hover
                    color white
                    cursor pointer
                    background: var(--panel)
                button.active
                    color white
                    background: var(--accent)
                button#windows
                    border-top-left-radius: 7px

            div.info
                margin 0
                padding 1rem 1rem 2rem 1rem
                background-color: hsl(255, 80%, 7%)
                border-bottom-left-radius: 7px
                border-bottom-right-radius: 7px
                div.link-div
                    margin 1rem
                div.list-div
                    margin auto
                    margin-top 0
                    display inline-block
                    ul 
                        margin 0
                    li
                        margin-bottom 0.5rem
                        text-align left
                        font-size 1.25rem
                p
                    margin-top 3rem
                    font-size 1.25rem

@media all and (max-width: 1000px) 
    div.panel 
        margin-top: 10rem;
    
        a.anchor 
            top: -9rem;
    
@media all and (max-width: 600px) 
    div.panel 
        margin-top: 16rem;
    
        a.anchor 
            top: -15rem;
    
</style>
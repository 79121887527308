<template>
    <form @keydown.enter="validate">
        <email-form-input @email-change="setEmail"/>
        <password-form-input :is-confirm="false" @password-change="setPassword"/>
        <password-form-input :is-confirm="true" @password-change="setConfirm"/>
        <!-- <button type="button" @click="recaptcha">Execute recaptcha</button> -->
        <p>By signing up, you agree to our <router-link to='/privacy'>privacy policy</router-link> and <router-link to='/terms'>terms of service</router-link> <input type="checkbox" v-model="isChecked"></p>        
        <agreement-dialog 
            class="extra" 
            v-if="showExtra" 
            @close-by-cancel="closeDialogByCancel"
            @close-by-agree="closeDialogByAgree"
        />
        <div class="notice" v-if="dialogClosedByCancel">
            <p>You must agree to the
                <span @click="dialogClosedByCancel = false">agreement</span>
                in order to sign up
            </p>
        </div>
        <action-button button-text="Sign Up" @click="validate" :disabled="!this.enableSignUp"/>
    </form>

</template>

<script>
import EmailFormInput from "@/components/auth/EmailFormInput.vue"
import PasswordFormInput from "@/components/auth/PasswordFormInput.vue"
import ActionButton from "@/components/auth/ActionButton.vue"
import AgreementDialog from "./AgreementDialog.vue"
// import authMixin from "@/mixins/authMixin.js"
// import { recaptchaLoaded, executeRecaptcha } from 'vue-recaptcha-v3'
import auth from "@/middleware/auth"
import axios from 'axios'


export default {

    components: { EmailFormInput, PasswordFormInput, ActionButton, AgreementDialog },
    // mixins: [authMixin],
    data() {
        return {
            email: null,
            password: null,
            confirm: null,
            isChecked: false,
            date: new Date().toLocaleDateString(),
            signature: '',
            dialogClosedByCancel: false,
            dialogClosedByAgree: false
        }
    },

    computed: {
        showExtra() {
            return this.isChecked && 
            this.email.length > 0 &&
            this.password.length > 0 &&
            this.confirm.length > 0 &&
            !this.dialogClosedByCancel &&
            !this.dialogClosedByAgree
        },
        enableSignUp() {
            return this.dialogClosedByAgree &&
            this.signature.length > 0
        }
    },

    methods: {
        
        // async recaptcha() {
        //     await this.$recaptchaLoaded()
        //     const token = await this.$recaptcha('login')
        //     console.log(token)
        // },
        setEmail(value) {
            this.email = value;
        },
        setPassword(value) {
            this.password = value;
        },
        setConfirm(value) {
            this.confirm = value;
        },
        validate() {
            if (!auth.isValidEmail(this.email) || !auth.isValidPassword(this.password)) {
                this.$store.dispatch('callToast', "Error: Please enter valid email and password.")
                return;
            } else if (this.password !== this.confirm) {
                this.$store.dispatch('callToast', "Error: Passwords need to match.")
                return;
            } else if(!this.isChecked) {
                this.$store.dispatch('callToast', "Error: Must agree to privacy policy and terms of service.")
                return
            } else { this.signUp()}
        },

        closeDialogByCancel() {
            this.dialogClosedByCancel = true;
        },

        closeDialogByAgree(signature) {
            this.signature = signature;
            this.dialogClosedByCancel = false;
            this.dialogClosedByAgree = true;
        },

        async signUp() {
            // await this.recaptcha()
            // await this.$store.dispatch('signUp', {email: this.email, password: this.password})
            this.$router.push('/auth/login')
        }

    },

    watch: {
        email: function (val) {
            if (val.length > 16) {
                this.hideBackground = true
            } else {
                this.hideBackground = false
            }
        }

    },

    mounted() {
        axios.get('https://www.cloudflare.com/cdn-cgi/trace').then(resp => {
        // Convert key-value pairs to JSON
        // https://stackoverflow.com/a/39284735/452587
        // data = data.trim().split('\n').reduce(function(obj, pair) {
        //     pair = pair.split('=');
        //     return obj[pair[0]] = pair[1], obj;
        // }, {});
        console.log(resp.data);
});
    }
    
}
</script>

<style lang="stylus" scoped>
    form 
        display flex
        flex-flow column
        width 100%

        p 
            display inline
            color hsl(0, 0%, 50%)
            font-family var(--font)
            font-size 1.1rem
            margin 1rem 0.5rem 0.5rem 0.5rem            
            input 
                vertical-align: middle
                width 1rem
                height 1rem

        input.email 
            width 22rem
            font-size 1.3rem
            padding .7rem 1.3rem
            border none
            border-radius 7px
            color var(--text)
            text-align center
            background-color var(--panel)
            background-image url('../../..//assets/img/email.svg')
            background-repeat no-repeat
            background-position 1rem
            background-size 1.7rem 1.7rem
            font-family var(--font)

            &:placeholder 
                opacity .6
                text-align center
                color var(--text)

            &:focus::placeholder 
                color transparent

        input.longEmail 
            background-image none

        div.password-row 
            position: relative
            display flex
            flex-flow row
            border none
            
            input.password
                background-image: url(../../../assets/img/password.svg)
                background-color var(--panel)
                border none
                background-repeat no-repeat
                background-position 1rem
                background-size 1.7rem 1.7rem
                font-family var(--font)
                color var(--text)
                font-size 1.3rem
                text-align center
                flex-grow 2
                border-radius 7px
                padding .7rem 1.3rem

                &:placeholder 
                    opacity .6
                    text-align center
                    color var(--text)

                &:focus::placeholder 
                    color transparent

            input.confirm 
                background-image: url(../../../assets/img/repeat-sign.svg)
                background-size 1.5rem
                background-position 1.2rem
                width 22rem

        div.notice
            span 
                font-weight bold
                color hsl(0, 0%, 60%)
                &:hover
                    cursor pointer
                    color hsl(0, 0%, 80%)

    a, a:hover, a:focus, a:active 
        text-decoration: none;
        margin-left 0.1rem
    a
        margin-bottom 0
        font-family var(--font)
        color hsl(0, 0%, 60%)
        font-weight bold
        font-size 1.1rem
        &:hover
            cursor pointer
            color hsl(0, 0%, 80%)

@media all and (max-width: 600px)
    form
        div.email-row
            margin 0.5rem
            align-self center
            width 100%
            input.email
                font-size 1rem
        div.password-row
            margin 0.5rem
            align-self center
            width 100%
            input.password
                font-size 1rem
        p 
            font-size 0.9rem
            input 
                vertical-align: middle
                width 0.9rem
                height 0.9rem
    div.button-row
        button
            font-size 1rem
    a
        font-size 0.9rem

</style>
<template>
    <div class="password-row">
        <input 
            class="password" 
            :class="{'longPassword': hidePasswordBackground, 'confirm': isConfirm}" 
            :type="passwordFieldType" 
            :placeholder="placeholder" 
            name="password" 
            v-model.trim="password"
        >
        <show-hide-btn 
            @togglepw="passwordFieldType = passwordFieldType === 'password' ? 'text' : 'password'"
        />
    </div>
</template>

<script>
import ShowHideBtn from "./ShowHideBtn.vue"


export default {
    props: ['isConfirm'],
    components: { ShowHideBtn },
    data() {
        return {
            password: null,
            passwordFieldType: 'password',
            hidePasswordBackground: false,
            placeholder: this.isConfirm ? 'Confirm' : 'Password'
        }
    },
    watch: {
        password: function (val) {
            if (val.length > 13) {
                this.hidePasswordBackground = true;
            } else {
                this.hidePasswordBackground = false;
            }
            this.$emit('password-change', this.password);
        }
    }
    
}
</script>

<style lang="stylus" scoped>
    div.password-row
        position: relative
        display flex
        flex-flow row
        margin 0.75rem
        border none

        input.password
            width 100%
            font-size 1.3rem
            padding .7rem 1.3rem
            background-image: url(../../assets/img/password.svg)
            background-color var(--panel)
            border none
            background-repeat no-repeat
            background-position 1rem
            background-size 1.7rem 1.7rem
            font-family var(--font)
            color var(--text)
            text-align center
            flex-grow 2
            border-radius 7px

            &:placeholder 
                opacity .6
                text-align center
                color var(--text)

            &:focus::placeholder 
                color transparent
        
        // this needs to come before longPassword
        input.confirm
            background-image: url(../../assets/img/repeat-sign.svg)

        input.longPassword 
            background-image none


@media all and (max-width: 600px)
    div.password-row
        margin 0.5rem
        align-self center
        width 100%
        input.password
            font-size 1rem
</style>
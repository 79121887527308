<template>
    <div class="container">
        <iframe 
            src="./cookie.html" 
            frameborder="0">
        </iframe>
    </div>
</template>

<style scoped lang="stylus">
div.container
    width clamp(20rem, 70%, 60rem)
    height: calc(95vh - 15rem)
    color var(--text)
    margin auto
    margin-top 10rem
    background-color: hsl(0, 0%, 60%)
    padding 2%
    border-radius: 7px

    iframe 
        width 100%
        height 100%
        background-color: hsl(0, 0%, 70%)
        border-radius: 5px
        padding 3px

</style>
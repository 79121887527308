<template>
    <div    
        class="background" 
        tabindex="0"
        ref="dialog"
    >
        <div class="foreground">
            <div class="agreement">
                <iframe 
                    src="../privacy.html" 
                    frameborder="0">
                </iframe>
            </div>
            <p>
                NOTE THAT THE DOCUMENT ABOVE IS SIMPLY A PLACEHOLDER. WE ARE NOT ACCEPTING USERS AT THIS TIME, AND SIGNUP FUNCTIONALITY IS NOT ENABLED. 
            </p>
            <p>
                By signing below, I acknowledge that I am agreeing to the conditions of the contract displayed above. My electronic signature shall be deemed to have the same legal effect as delivery of an original executed copy of this contract.
            </p>
            
            <div class="acknowledge">
                <div class="signature">
                    <h6>Signature</h6>
                    <input type="text" name="signature" id="signature" v-model="signature">
                </div>
                <div class="date">
                    <h6>Date</h6>
                    <input type="text" name="date" id="date" v-model="date" readonly>
                </div>
            </div>
            <div class="button-group">
                <action-button button-text="Cancel" button-type="cancel" @click="closeDialogByCancel"/>
                <action-button button-text="Agree" @click="closeDialogByAgree" :disabled="!canSubmit"/>
            </div>
        </div>
    </div>
</template>

<script>
import { directive } from "vue3-click-away";
import ActionButton from "@/components/auth/ActionButton.vue"

export default {
    components: {
        ActionButton
    },
    directives: {
        ClickAway: directive
    },
    data() {
        return {
            signature: '',
            date: new Date().toLocaleDateString(),
        }
    },
    computed: {
        canSubmit() {
            return this.signature.length >= 3;
        }
    },
    methods: {
        closeDialogByCancel() {
            this.$emit("close-by-cancel");
        },
        closeDialogByAgree() {
            this.$emit("close-by-agree", this.signature);
        }
    }, 
    mounted() {
        this.$refs.dialog.focus()
    } 
}
</script>

<style lang="stylus" scoped>

    div.background
        background-color: var(--modal-bg-dark)
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;
        display: flex;
        align-items: center;
        z-index: 1;
        font-family: var(--font)
        overflow-y: auto
    
        div.foreground
            background: var(--solid-panel)
            display: flex
            flex-flow column
            align-items: center
            background-size: 400% 400%;
            color var(--text)
            font-size 1.5rem
            border-radius: 5px;
            border 1px solid var(--charcoal)
            box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
            max-width 45rem
            margin-left: auto;
            margin-right: auto;
            padding: 1rem 2rem 1rem 2rem;
            p 
                font-size: 1rem
                margin-bottom: 0
            
            div.agreement 
                background-color: hsl(0, 0, 70%)
                height 30vh
                width: 100%
                iframe 
                    height: 100%
                    width: 100%
    
            div.acknowledge 
                input 
                    font-size: 1.3rem
                    padding: 3px
                    text-align: center

            div.button-group 
                margin 2rem 0 1rem 0
                button 
                    margin 0.5rem
            
            input
                font-size: 1.5rem
                padding: 3px
                background-color: var(--brighter-panel)
                color: hsl(0, 0, 80%)
                border-radius: 5px
                border: 3px solid var(--brighter-panel)

            h6 
                margin 1.5rem 0.5rem 0.5rem 0.5rem
                

    @media screen and (max-width: 1000px)
        div.background
            div.foreground
                font-size 1.25rem

    @media screen and (max-width: 600px)
        div.background
            div.foreground
                p
                    font-size 0.8rem
                font-size 1.1rem



</style>

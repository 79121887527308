export default {
    state: {
        quitAsking: false,
        query: '',
        networkMsg: null,
        isNewMsg: false, 
        isSafe: false,
        select0: {
            value: null,
            key: null
        },
        select1: {
            value: null,
            key: null
        },
        select2: {
            value: null,
            key: null
        },
        loading: false,
        showPaywall: false,
        trialEnded: false
    },
    mutations: {
        setQuery(state, query) {
            state.query = query
        },
        setQuitAsking(state, bool) {
            console.log('Setting ' + bool);
            state.quitAsking = bool
        },
        setSelect(state, {select, num}) {
            state[num].value = select
        },
        setSelectKey(state, {key, num}) {
            state[num].key = key
        },
        setLoading(state, status) {
            state.loading = status
        },
        setNetworkMsg(state, msg) {
            state.networkMsg = msg
        },
        setIsNewMsg(state, bool) {
            state.isNewMsg = bool
        },
        setSelectsAll(state) {
            state.select0.value = "[ALL]" 
            state.select1.value = "[ALL]" 
            state.select2.value = "[ALL]" 
        },
        setSafe(state, bool) {
            state.isSafe = bool
        },
        setShowPaywall(state, bool) {
            state.showPaywall = bool
        },
        setTrialEnded(state, bool) {
            state.trialEnded = bool
        }
        
    },
    actions: {
        async callToast({commit}, msg) {
            commit('setIsNewMsg', true)
            commit('setNetworkMsg', msg)
            await new Promise(resolve => setTimeout(resolve, 5000))
            commit('setIsNewMsg', false)
        },
        
    }

    
}


<template>
    <button :class="this.buttonType" type="button" >{{buttonText}}</button>
</template>

<script>
export default {
    props: ['buttonText', 'buttonType'],
}
</script>

<style lang="stylus" scoped>
    button
        padding 0.8rem
        width 10rem
        font-family: var(--font)
        background-color var(--accent)
        color: var(--text)
        margin 1rem auto
        border: none
        border-radius: 7px;
        font-size: 1.3rem;
        box-shadow 1px 1px 3px 1px rgba(0,0,0,.2)

        &:hover 
            transform scale(1.03)
            cursor pointer

    button.warn 
        background-color: var(--warn)

    button.cancel 
        background: var(--charcoal)

    button:disabled
        background-color: var(--charcoal)
        &:hover
            transform scale(1)
            cursor: auto
            
        
@media all and (max-width: 1000px)
    button
        font-size 1.2rem
        width 9rem
        padding 0.7rem


@media all and (max-width: 600px)
    button
        font-size 1.1rem
        width 7rem
        padding 0.6rem
        border-radius: 5px
    // a
    //     font-size 0.9rem
</style>
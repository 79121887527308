<template>
    <div class="paywall">
        <div v-if="trialEnded">
            <p v-if="trialEnded">
                Thank you for using ShutterSort – we really hope that you've enjoyed using the platform.
                Unfortunately your free trial period has ended. Please subcribe to continue accessing your account.
            </p>
            <button @click="subscribe">Subscribe</button>
        </div>
        <div v-else>
            <p> Welcome back! Your account is currently inactive. Please update payment to continue access.</p>
            <button><a :href="updateUrl">Update</a></button>
        </div>
    </div>
</template>

<script>
import auth from '@/middleware/auth'
import subscription from '@/middleware/subscription'
import apiCalls from '@/services/apiCalls'
export default {
    data() {
        return {
            updateUrl: ''
        }
    },
    computed: {
        trialEnded() {
            return this.$store.state.uiStore.trialEnded;
        }
    },
    methods: {
        subscribe() {
            subscription.subscribe();
        }
    },
    async mounted() {
        const payload = {
            userId: auth.getOwner()
        };
        const res = await apiCalls.getUserDetails(payload);
        this.updateUrl = res.data.updateUrl;
    }
}
</script>

<style lang="stylus" scoped>
    @keyframes background-transition {
        0% {
            background-position 1% 0%
        }
        50% {
            background-position 99% 100%
        }
        100% {
            1% 0%
        }
    }

    div.paywall
        text-align: center
        font-family var(--font)

        p
            font-size 1.4rem


        button 
            padding 1rem
            background-color var(--accent)
            color: var(--text)
            margin 1rem
            border: none
            border-radius: 7px;
            font-size: 1.5rem;
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);

            &:hover 
                transform scale(1.05)
                cursor pointer
                box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);

            a
                padding 1rem
                border-radius 7px
                text-decoration: none
                color var(--text)


        .fade-enter-active,
        .fade-leave-active {
            transition: opacity 0.3s;
        }
        .fade-enter,
        .fade-leave-to {
            opacity: 0;
        }

@media screen and (max-width: 1000px)
    div.paywall
        p
            font-size 1.25rem
        button 
            padding 0.75rem
            font-size 1.25rem
            a
                padding 0.75rem

@media screen and (max-width: 600px)
    div.paywall
        p
            font-size 1.1rem
        button 
            font-size 1.1rem

</style>
import  { createApp } from 'vue';
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import Row from '@/components/common/Row.vue'
import Spinner from '@/components/common/Spinner.vue'
import Toast from '@/components/common/Toast.vue'
import MainPanel from '@/components/common/MainPanel.vue'
import VueScrollTo from 'vue-scrollto'
// import { VueReCaptcha } from 'vue-recaptcha-v3'


const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueScrollTo)
// app.use(VueReCaptcha, { siteKey: '6LfIwAMeAAAAAMp45LiUVSwqEt1M9Oi31rQ80udz' })
app.component('spinner', Spinner)
app.component('toast', Toast)
app.component('flex-row', Row)
app.component('main-panel', MainPanel)
app.mount('#app')

        

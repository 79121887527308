<template>
    <centered-panel>
        <spinner bg='login' v-if="loading"/>
        <logo v-else/>
        <router-link to='/'><h1 class='site-title'>Shutter<span>Sort</span></h1></router-link>
        <login-form/>
    </centered-panel>
</template>

<script>
import CenteredPanel from '@/components/auth/CenteredPanel'
import Logo from '@/components/common/Logo'
import LoginForm from './LoginForm'

export default {
    
    components: {  Logo, LoginForm, CenteredPanel },

    computed: {
        
        loading() {
            return this.$store.state.uiStore.loading
        },
    }

}
</script>

<style scoped lang='stylus'>

    a
        cursor pointer
        text-decoration: none

    h1.site-title 
        font-family: var(--title-font)
        font-style: normal;
        font-weight: bold;
        font-size: 3.2rem;
        color: var(--title)
        margin 1rem 0

        span    
            opacity .5

    h1.site-title:hover
        color white

@media all and (max-width: 600px)
    h1.site-title
        font-size: 2.5rem

</style>
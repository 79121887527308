import axios from 'axios'
import store from '@/store'
import auth from '@/middleware/auth'

const apiClient = axios.create({
    baseURL: "https://gateway.shuttersort.io",
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
    },
});

apiClient.interceptors.request.use((config) => {
    let token = auth.getToken();
    config.headers.Authorization =  `Bearer ${token}`;
    return config
})

apiClient.interceptors.request.use((config) => {
    store.commit('setLoading', true)
    return config
})

apiClient.interceptors.response.use((response) => {
    store.commit('setLoading', false)
    return response
})

apiClient.interceptors.response.use((response) => {
    return response
}, async function (error) {
    if (!error.response) {
        store.dispatch('callToast', 'Network Error');
    } else {
        const originalConfig = error.config;
        if (error.response.status == 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            await store.dispatch('refreshToken');
            let token = sessionStorage.getItem('token')
            originalConfig.headers['Authorization'] = `Bearer ${token}`;
            return apiClient(originalConfig)
        }
    }
    store.commit('setLoading', false);
    return Promise.reject(error);
});

export { apiClient }
<template>
    <div>
        <a class="anchor" id="pricing"></a>
        <h1>Pricing</h1>
        <hr>
        <h2>$35 per month, but...</h2>
        <p>We are currently testing our beta release, so you can try ShutterSort for free! 
            Once the release becomes stable, ShutterSort will cost $35 per month. However,
            all new users will receive a 30 day free-trial period. Your subscription will include</p>
        <div class="pricing-box">
            <h2>10 Active Projects</h2>
            <h2>10,000 Subjects Per Project</h2>
      
        </div>
    </div>
</template>

<style scoped lang="stylus">

    div.pricing-box
        display inline-block
        border: 1px solid rgb(72, 61, 165);
        border-radius: 7px; 
        padding 1rem 2rem
        margin-top 1rem
    
@media all and (max-width: 600px)
    div.pricing-box
        padding 0.5rem 1rem

    
</style>
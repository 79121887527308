<template>
    <div>
        <a class="anchor" id="subscribe"></a>
        <h1>Subscribe</h1>
        <hr>
        
        <div>
            <h2>Ready to try it out?</h2>
            <p>Sign up and get a 30 day free trial period.</p>
            <p>You'll get immediate access to your account, with no payment info required. After your free trial has ended, you'll be prompted to enter payment and subscribe.</p>
        </div>

        <button @click="this.$router.push('/auth/sign-up')">Sign up!</button>
    </div>
</template>

<style scoped lang="stylus">

    button 
        padding: 0.75rem;
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        color: rgb(200, 200, 200);
        font-weight: bold;
        font-size: 1.5rem;
        background-color: var(--accent);
        border-radius: 0.25rem;
        border: none;
        margin-top: 1rem;
        font-family var(--font)
    
        &:hover 
            transform: scale(1.06)
            cursor: pointer;
    
@media all and (max-width: 600px)
    button 
        font-size 1.1rem
</style>
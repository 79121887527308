import SalesPage from '@/pages/site/SalesPage'
import TermsPage from '@/pages/site/TermsPage'
import PrivacyPage from '@/pages/site/PrivacyPage'
import CookiePage from '@/pages/site/CookiePage'
// import DataPage from '@/pages/site/DataPage'
import SuccessPage from '@/pages/site/SuccessPage'
import CancelPage from '@/pages/site/CancelPage'
import DownloadPage from '@/pages/site/DownloadPage'
import DocumentationPage from '@/pages/site/DocumentationPage'
import ExplanationPage from '@/pages/site/ExplanationPage'
import auth from '@/middleware/auth'



export default [
    {
        path: 'sales',
        component: SalesPage,
        alias: '/'
    },
    {
        path: 'terms',
        component: TermsPage,
        beforeEnter: () => {
            window.scrollTo(0, 0);
        }
    },
    {
        path: 'privacy',
        component: PrivacyPage,
        beforeEnter: () => {
            window.scrollTo(0, 0);
        }
    },
    {
        path: 'cookie',
        component: CookiePage,
        beforeEnter: () => {
            window.scrollTo(0, 0);
        }
    },
    // {
    //     path: 'data',
    //     component: DataPage,
    //     beforeEnter: () => {
    //         window.scrollTo(0, 0);
    //     }
    // },
    {
        path: 'success',
        component: SuccessPage,
        beforeEnter: auth.authenticate
    },
    {
        path: 'cancel',
        component: CancelPage,
        beforeEnter: auth.authenticate
    },
    {
        path: 'download',
        component: DownloadPage
    },
    {
        path: 'docs',
        component: DocumentationPage,
        beforeEnter: () => {
            window.scrollTo(0, 0);
        }
    },
    {
        path: 'explain',
        component: ExplanationPage,
        beforeEnter: () => {
            window.scrollTo(0, 0);
        }
    }
]
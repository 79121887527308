<template>
    <div>
        <a class="anchor" id="features"></a>
        <h1 class="sales">Features</h1>
        <hr class="sales">
        <div class="features">
            <div>
                <img src="@/assets/img/accurate.svg" alt="accurate icon">
                <h2>Maximal<span>Accuracy</span></h2>
                <p>ShutterSort uses custom machine learning models to recognize image codes and provide exceptionally high match rates</p>
            </div>
            <div class="simple-workflow">
                <img src="@/assets/img/workflow2.svg" alt="workflow icon">
                <h2>Simple<span>Workflow</span></h2>
                <p>All sorting is performed locally using the ShutterSort desktop client – no need to upload an entire photoshoot of large image files</p>
            </div>
            <div class="fast-sorting">
                <img src="@/assets/img/fast2.png" alt="fast icon">
                <h2>Fast<span>Sorting</span></h2>
                <p>Sort a project within a matter of minutes, replacing hours of tedious manual work</p>
            </div>
        </div>
    </div>
</template>


<style scoped lang="stylus">

div.features
    display: flex;
    flex-flow row
    justify-content: space-between
    align-items: flex-start;
    padding-top 2rem
    padding-bottom 0

    div 
        flex 1
        margin-left: 1rem
        margin-right: 1rem

        hr
            width: 11rem;

        img 
            filter: invert(85%) sepia(28%) saturate(4378%) hue-rotate(191deg) brightness(110%) contrast(107%);
            width: 10rem
            height: 10rem
            margin-bottom 0
        
        h2 
            span 
                display list-item 
                list-style none
        
    
@media all and (max-width: 1000px) 
    div.features 
        flex-flow column

        div 
            margin-top 1rem

            img 
                margin-bottom: 0
                width: 8rem
                height: 8rem

        div.simple-workflow
            margin-top 2rem
        
        div.fast-sorting
            margin-top 2rem   


@media all and (max-width: 600px) 
    div.features 
        flex-flow column

        div 
            img 
                margin-bottom: 0
                width: 6rem
                height: 6rem

</style>
<template>
    <button alt="upload button" class='upload-button'>
        <img src='@/assets/img/upload.svg' alt='upload icon'/>
        <div>Upload</div>
    </button>
</template>

<style lang="stylus" scoped>
    button.upload-button
        display flex
        flex-flow column
        background var(--modal-bg)
        border none 
        border-radius 7px
        color var(--text)
        padding 2rem
        font-family var(--font)
        display block
        margin 2rem auto 1rem auto
        box-shadow 1px 1px 3px 1px rgba(0,0,0,.5)

        img 
            width 3rem
            filter var(--svg-filter)

        div
            color white
        
        &:hover
            cursor pointer
            transform scale(1.1)

@media screen and (max-width: 1000px) 
    button.upload-button
        padding 1.5rem
        img
            width 2.5rem

@media screen and (max-width: 600px)
    button.upload-button
        padding 1rem
        img
            width 2rem
</style>
export default {
    searchSubjects(state) {
        // return state.subjects
        if(state.activeSubjects.length === 0) {
            return "Project has no subjects."
        } else {
            return state.activeSubjects.filter(subject => {
                return subject.lastName.toLowerCase().startsWith(state.uiStore.query.toLowerCase())
            })
        }
    },
    getSubject: (state) => (id) => {
        return state.activeSubjects.find(subject => subject.id == id);
    },
    getActiveProject(state) {
        return state.activeProject;
    },
    getActiveSchema(state) {
        return state.activeProject.schema;
    },
    hasProjects(state) {
        return state.projects.length > 0;
    },
    hasActiveSubjects(state) {
        return state.activeSubjects.length > 0;
    }
    
    
}
<template>
    <form @keydown.enter="verify">
        <p>Please enter your password to proceed</p>
        <div class="pw-input">
            <input type="password" v-model="password">
        </div>
        <action-button
            button-text="Cancel"
            button-type="cancel"
            @click="closeDialogue"
        />
        <action-button
            v-if="password.length > 7"
            button-text="Submit"
            button-type="normal"
            @click="verify"
            class="submit-btn"
        />
    </form>
</template>

<script>
import auth from '@/middleware/auth.js'
import authCalls from '@/services/authCalls.js'
import ActionButton from '@/components/auth/ActionButton.vue'
export default {
    components: {
        ActionButton
    },
    data() {
        return {
            password: '',
        }
    },
    methods: {
        async verify() {
            this.$emit("close");
            const payload = {
                email: auth.getEmail(),
                password: this.password
            };
            authCalls.login(payload).then(() => {
                this.$emit("verified");
            }).catch(() => {
                this.$emit("failed");
            })
        },

        closeDialogue() {
            this.$emit("close");
        }
    }
}
</script>

<style lang="stylus" scoped>

    form
        text-align: center
        margin-bottom 1rem

        h1
            font-size 2.5rem

        p 
            font-size 1.5rem
        
        div.pw-input
            margin 2rem 0 1rem 0

            input 
                font-size: 1.5rem
                border-radius: 5px
                width: 16rem
                padding: 0.25rem
                background-color: hsla(0, 0%, 100%, 0.2)
                color: var(--text)
                text-align: center
        
        button.submit-btn
            margin-left 2rem

@media all and (max-width: 1000px)
    div.delete-confirm
        margin-bottom 0.75rem

        h1
            font-size 2rem

        p 
            font-size 1.25rem

        div.pw-input
            input 
                font-size 1.25rem
                width 14rem

        button.submit-btn
            margin-left 1.5rem

@media all and (max-width: 600px)
    div.delete-confirm
        margin-bottom 0.5rem

        h1
            font-size 1.5rem

        p 
            font-size 1rem

        div.pw-input
            input 
                font-size 1rem
                width 12rem

        button.submit-btn
            margin-left 1rem
    
</style>
<template>

    <form @keydown.enter.prevent="submit">
        <p>Hold up – you are about to delete subject</p>
        <h1>{{this.name}}</h1>
        <p>This action is permanent and cannot be undone</p>

        <action-button
            button-text="Cancel"
            button-type="cancel"
            @click="closeDialog"
        />
        <action-button
            class="submit-button"
            button-text="Delete"
            button-type="warn"
            @click="submit"
        />
        <flex-row class="quit-row" >
            <span>Quit Asking</span> 
            <quit-check-box class="check-box"/>
        </flex-row>
    </form>
</template>

<script>
import ActionButton from '@/components/auth/ActionButton.vue'
import QuitCheckBox from '@/components/common/QuitCheckBox.vue'
export default {
    props: ['name'],
    components: {
        ActionButton, QuitCheckBox
    },
    methods: {
        async submit() {
            this.$emit('delete');
            this.$emit('close');
        },
        async closeDialog() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="stylus" scoped>

    form
        text-align: center
        margin-bottom 1rem
        padding-left 1rem
        padding-right 1rem

        p span
            font-size 1.5rem

        h1 
            color: white
            font-weight: bold
            font-family: monospace
            font-size 1.75rem

        button 
            font-size 1.25rem
            box-shadow 1px 1px 3px 1px rgba(0,0,0,.2)

        button.submit-button 
            margin-left: 2rem

        div 
            margin-top 1rem
            justify-content: center
            span 
                margin-right 0.5rem


@media all and (max-width: 1000px)
    form
        margin-bottom 0.75rem

        p span
            font-size 1.25rem

        button 
            font-size 1rem
        
        button.submit-button 
            margin-left: 1.5rem
        
        h1 
            font-size: 1.5rem


@media all and (max-width: 600px)
    form
        margin-bottom 0.5rem

        p span
            font-size 1rem
        
        button 
            font-size .9rem

        button.submit-button 
            margin-left: 1rem

        h1 
            font-size: 1.25rem
    
</style>

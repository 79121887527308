<template>
    <div class="file-select-container">
        
        <selected-file 
            v-if="file"
            :file="file"
            @remove="removeFile"    
        />

        <label v-else class="file-select">
            <button type='button' class="select-button" @click="openFileInput">
                <span>Create Project from File</span>
                <!-- <input alt="select csv file from your device" type="file" accept=".csv, .xlsx" @change="addFile"/> -->
            </button>
            <input alt="select csv file from your device" id="fileInput" type="file" accept=".csv" @change="addFile"/>
        </label>
        
    </div>
</template>

<script>
import SelectedFile from './SelectedFile'
export default {
    components: {
        SelectedFile
    },
    data() {
        return {
            file: null,
        }
    },
    methods: {
        openFileInput() {
            document.getElementById('fileInput').click();
        },
        addFile(e) {
            this.file = e.target.files[0]
        },
        removeFile() {
            this.file = null
        }

    }
        
}
</script>

<style lang="stylus" scoped>
div.file-select-container 
    display flex
    flex-flow column
    justify-content center
    align-items center
    
    label.file-select 
        display flex
        flex-flow column
        align-items center

        button.select-button 
            color: var(--text);
            font-size 1.75rem
            border none
            background-color: var(--accent)
            font-family var(--font)
            text-align: center;
            overflow hidden
            border-radius 7px
            padding 1.25rem
            margin 1rem
            box-shadow 1px 1px 3px 1px rgba(0,0,0,.25)

            &:hover
                cursor pointer
                transform scale(1.03)

        input[type="file"]
            display: none;
            width 1px 
            height 1px

@media screen and (max-width: 1000px) 
    div.file-select-container 
        label.file-select 
            button.select-button 
                font-size 1.4rem

@media screen and (max-width: 600px) 
    div.file-select-container 
        label.file-select 
            button.select-button 
                font-size 1.1rem
                padding 1rem
    
</style>
<template>
    <div class="center-panel">
        <slot/>
    </div>
</template>

<style lang="stylus" scoped>
    div.center-panel
        margin auto
        width 30rem
        text-align: center;
        background var(--solid-panel)
        padding 3rem
        border-radius 10px
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        // overflow auto

// @media all and (max-width: 1000px)
//     div.center-panel
//         width 30rem
//         padding 3rem
        

@media all and (max-width: 600px) 
    div.center-panel
        width clamp(300px, 90%, 400px)
        padding 2rem

</style>
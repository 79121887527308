import apiCalls from "../../services/apiCalls"
import auth from "@/middleware/auth"

export default {
actions: {
    async fetchProjects({ commit }) {
        let payload = {
            'owner': auth.getOwner(),
        }
        try {
            let res = await apiCalls.fetchProjects(payload);
            commit('setShowPaywall', false);
            if(res.data.length == 0) {
                commit('setProjects', [])
            } else {
                commit('setProjects', res.data)
            }
        } catch(err) {
            if (err.response.status == 402) {
                if (err.response.data.toLowerCase().includes('trial')) {
                    commit('setTrialEnded', true) 
                }
                commit('setShowPaywall', true);
            } else {
                throw err;
            }
        }
    },

    async deleteProject({dispatch}, id) {
        let payload = {
            'owner': auth.getOwner(),
            "id": id
        }
        try {
            await apiCalls.deleteProject(payload)
            // dispatch('callToast', `Project deleted`)
        } catch (err) {
            dispatch('callToast', `Error: ${err.message}`)
        }
    },

    async renameProject({dispatch}, payload) {
        let name = {
            'name': payload.name,
            "id": payload.id
        }
        try {
            await apiCalls.renameProject(name)
            // dispatch('callToast', `Project renamed`)
        } catch (err) {
            dispatch('callToast', `Error: ${err.message}`)
        }
    },

}
}


<template>
    <div    
        class="background" 
        @keydown.esc="closeDialogue"
        tabindex="0"
        ref="dialogue"
    >
        <div class="foreground" v-click-away="closeDialogue">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { directive } from "vue3-click-away";

export default {
    directives: {
        ClickAway: directive
    },
    methods: {
        closeDialogue() {
            this.$emit("close");
        }
    }, 
    mounted() {
        this.$refs.dialogue.focus()
    } 
}
</script>

<style lang="stylus" scoped>
@keyframes background-transition {
    0% {
        background-position 1% 0%
    }
    50% {
        background-position 99% 100%
    }
    100% {
        1% 0%
    }
}
    div.background
        background-color: var(--modal-bg-dark)
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1rem;
        display: flex;
        align-items: center;
        z-index: 1;
    
        div.foreground
            background-image: (linear-gradient(to left, hsla(210, 30%, 40%, 1), hsla(310, 30%, 40%, 1))); // Bens change
            // background-image: (linear-gradient(to left, hsla(233, 40%, 37%, 1), hsla(325, 40%, 37%, 1))); // compromise
            // background-image: (linear-gradient(to left, hsla(208, 40%, 50%, 1), hsla(312, 40%, 50%, 1))); // OG
            animation: background-transition 9s ease infinite;
            display: flex
            flex-flow column
            align-items: center
            background-size: 400% 400%;
            color var(--text)
            font-size 1.5rem
            border-radius: 5px;
            border 2px solid hsl(0, 0, 50%)
            box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
            max-width 45rem
            margin-left: auto;
            margin-right: auto;
            padding: 1rem 2rem 1rem 2rem;

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    @media screen and (max-width: 1000px)
        div.background
            div.foreground
                padding: 0.5rem 1rem 0.5rem 1rem;
                font-size 1.25rem
                max-width 33rem

    @media screen and (max-width: 600px)
        div.background
            div.foreground
                max-width 28rem
                font-size 1.1rem

    @media screen and (max-width: 400px)
        div.background
            div.foreground
                max-width 20rem

</style>

<template>
    <div class="inner-panel">
        <form id="create-project" @keyup.enter="submitProject">
            <label class="project-name">Project Name</label>
            <input ref="projectName" v-model.trim="shootName" type="text">
            <p>Each subject will automatically contain fields for first name and last name. 
                In addition to these, you can enter up to three custom fields that will be 
                associated with each subject.</p>
            <label>Category 1</label>
            <input v-model.trim="cat1" :disabled="shootName == ''" type="text">
            <label>Category 2</label>
            <input v-model.trim="cat2" :disabled="cat1 == ''" type="text">
            <label>Category 3</label>
            <input v-model.trim="cat3" :disabled="cat2 == ''" type="text">
        </form>
        <div class="button-div">
            <button alt="cancel" type='button' class="cancelBtn" @click="cancel">
                Cancel
            </button>
            <button  v-if="this.shootName != ''" alt="create project" type='button' class="submitBtn" @click="submitProject">
                Submit
            </button>
            
        </div>
        
    </div>

</template>

<script>
export default {

    data() {
        return {
            shootName: '',
            cat1: '',
            cat2: '',
            cat3: ''
        }
    },

    methods: {
        async submitProject() {
            if (this.shootName.length > 0) {
                await this.$store.commit('setNewProjectName', this.shootName)
                let payload = [this.cat1, this.cat2, this.cat3].filter((e) => {
                    return !!e.trim()
                })
                await this.$store.dispatch('createProject', payload)
                await this.$store.dispatch('fetchProjects')
                this.$router.push({ name: "projects" });
            }
        },
        cancel() {
            this.$router.push('create-project')
        }
    },

    mounted() {
        this.$refs.projectName.focus()
        window.scrollTo(0, 0)
    }
    
}
</script>

<style scoped lang="stylus">
div.inner-panel
    background var(--brighter-panel)
    border-radius 7px
    width calc(100% - 6rem)
    max-width 60rem
    display flex 
    flex-flow column
    align-items center
    margin-top 3rem
    margin-bottom 3rem
    padding 2rem

    form 
        display flex 
        flex-flow column
        align-items center
        
        label
            border none
            font-size: 1.5rem
            margin-bottom 1rem

        label.project-name 
            font-size 2rem
                
        input
            -webkit-appearance: none; 
            appearance: none;
            width: 90%
            max-width 20rem
            font-size 1.3rem
            padding: 10px 20px;
            margin-bottom 1.5rem
            display: inline-block;
            border solid 2px var(--border)
            border-radius: 7px;
            box-sizing: border-box;
            color var(--text)
            text-align center
            background-color: var(--panel)
            font-family var(--font)
            
            &:focus
                background var(--bg)
                
            &::placeholder 
                opacity 1
                text-align center
                color var(--text)

        input:disabled 
            background-color: var(--brightest-panel)
            // background-color: none

        p
            font-size 1.4rem
            max-width: 80%
            margin-bottom: 2rem
            text-align: center

    div.button-div
        display flex
        justify-content: center
        align-items center

        button
            -webkit-appearance: none; 
            appearance: none;
            background var(--charcoal)
            color var(--text-color)
            font-family var(--font)
            font-size 1.5rem
            border none 
            border-radius 7px
            box-shadow 1px 1px 3px 1px rgba(0,0,0,.2)
            padding 0.75rem 1rem
            margin 1.5rem 1rem 1rem 1rem

            &:hover
                cursor pointer
                transform scale(1.03)

        button.submitBtn
            background var(--accent)

        button.cancelBtn
            background var(--charcoal)
    
    select.form-select::placeholder
        text-align center

@media screen and (max-width: 1000px) 
    div.inner-panel
        form
            label  
                font-size 1.3rem
            label.project-name 
                font-size 1.75rem
            input
                font-size 1.2rem
            p
                font-size 1.25rem
        div.button-div
            button
                font-size 1.4rem
                padding 0.75rem 1.5rem 0.75rem 1.5rem


@media screen and (max-width: 600px)
    div.inner-panel
        margin-top 2rem
        margin-bottom 2rem
        width calc(100% - 4rem)
        form
            label  
                font-size 1.2rem
            label.project-name 
                font-size 1.5rem
            input
                font-size 1rem
            p
                font-size 1rem
        div.button-div
            button
                font-size 1.1rem
</style>
<template>
    <div class="side-bar" >  

        <router-link alt="project page link" to="/app/projects" class="link-container" :class="{disabled: !hasProjects}">
            <img src="@/assets/img/photoshoots.svg" alt="camera icon">
            <div class="link-title">Projects</div>
        </router-link>

        <router-link alt="create project link" to="/app/create-project" class="link-container" :class="{disabled: isSafe}">
            <img src="@/assets/img/add-photoshoot.svg" alt="import icon">
            <div class="link-title">Create Project</div>
        </router-link>

        <router-link alt="manage projects link" to="/app/manage" class="link-container" :class="{disabled: !hasProjects || isSafe}">
            <img src="@/assets/img/manage-shoots.svg" alt="manage icon">
            <div class="link-title">Manage Projects</div>
        </router-link>

        <router-link alt="application settings link" to="/app/settings" class="link-container">
            <img src="@/assets/img/app-settings.svg" alt="gear icon">
            <div class="link-title">Settings</div>
        </router-link>
        
    </div>
</template>

<script>
export default {
    
    computed: {
        hasProjects() {
            return this.$store.getters.hasProjects
        },
        isSafe() {
            return this.$store.state.uiStore.isSafe
        }
    },

    async beforeCreate() {
        
        if (this.$store.state.projects.length == 0) {
            await this.$store.dispatch('fetchProjects');
            if (this.$store.state.projects.length == 0) {
                this.$router.push('create-project')
            }
        }
    }
    

}
</script>

<style lang="stylus" scoped>
    div.side-bar 
        width 17rem
        display inline-flex
        flex-flow column
        margin 0
        margin-top: 1rem
        
        a.link-container
            display flex
            flex-flow row
            align-items center
            width auto
            padding .4rem 1rem .4rem 0
            margin 1rem 0 0 0
            background var(--panel)
            border-top-right-radius:7px;
            border-bottom-right-radius:7px;
            text-decoration none 
            color var(--title)
            
            &:hover
                cursor pointer 
                transform translateX(1rem)
                // transform: scale(1.05)
                transform-origin left center
                background-color var(--brighter-panel)
        
            div.link-title
                font-family var(--font)
                font-size 2.25rem
                margin .5rem 0 

            img 
                margin 0 1rem
                height 2.5rem
                width 2.5rem
                filter var(--svg-filter)

        a.router-link-active  
            background var(--brightest-panel)
            width 18rem

            &:hover
                cursor pointer
                background-color var(--brighter-panel)
                transform: scale(1)


        a.disabled 
            background var(--greyed-out)
            pointer-events: none;

            div.link-title
                font-family var(--font)

            filter opacity(20%)

        a.help  
            position absolute
            width auto
            bottom 0
            border-radius 0 7px 0 0

            &:hover
                transform: scale(1.1)
                transform-origin left bottom

            img
                margin 0 1rem
                height 2rem
                width 2rem
                filter var(--svg-filter)
            
            div.help-title 
                font-family var(--font)
                font-size 1.5rem
                
        
@media screen and (max-width: 1000px) 
    div.side-bar 
        width auto
        display flex
        flex-flow row
        justify-content center
        margin-top 1rem

        a.link-container
            align-items: center
            margin 0 1rem 0 1rem
            padding 0 0.1rem 0 0.1rem
            width 10rem
            border-radius 10px

            &:hover
                transform none

            div.link-title
                font-size 1.25rem

            img 
                margin 0 1vw
                width 2rem
                filter var(--svg-filter)

        a.router-link-active  
            background var(--brightest-panel) !important

        a.add-subject-link 
            position fixed
            bottom 0
            left 0
            background var(--brightest-panel)  
        
@media screen and (max-width: 800px) 
    div.side-bar
        a.link-container
            margin 0 0.5rem 0 0.5rem
            div.link-title
                font-size 1.1rem
            img 
                margin 0 1vw
                width 1.5rem

@media screen and (max-width: 600px) 
    div.side-bar
        a.link-container
            padding-left 0.25rem
            padding-right 0.25rem
            margin 0 0.25rem 0 0.25rem
            div.link-title
                font-size 0.85rem
            img 
                margin 0 1vw
                width 1rem
    
</style>

import { authClient } from './authClient'

export default {

    login(payload) {
        const data = {
            'email': `${payload.email}`,
            'password': `${payload.password}`,
            "returnSecureToken": true
        };
        return authClient.post('accounts:signInWithPassword', data)
    },
    
    signUp(payload) {
        const data = {
            'email': `${payload.email}`,
            'password': `${payload.password}`
        };
        return authClient.post('accounts:signUp', data)
    },

    verifyEmail(payload) {
        return authClient.post('accounts:sendOobCode', 
            {
                'requestType': "VERIFY_EMAIL",
                'idToken': `${payload}`
            }
        )
    },

    verifyCaptcha(payload) {
        return authClient.post('captcha', payload)
    },

    resetPassword(email) {
        return authClient.post('accounts:sendOobCode', 
            {
                'requestType': "PASSWORD_RESET",
                'email': `${email}`
            }
        )
    },

    getUserData(payload) {
        return authClient.post('accounts:lookup', 
            {
                'idToken': payload
            }
        )
    },

    refresh(payload) {
        return authClient.post('token', payload)
    }


}


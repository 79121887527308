<template>

    <div 
        @click="toggle" 
        @keydown.esc="close"
        class="drop-down-container"
    >
            <button>
                <img src="@/assets/img/user.svg">
            </button>
    </div>
    <drop-down-content/>
</template>

<script>
import DropDownContent from './DropDownContent.vue'
export default {
    components: {
        DropDownContent
    },
    provide () {
        return {
            sharedState: this.sharedState
        }
    },
    data () {
        return {
            sharedState: {
                active: false
            }
        }
    },
    methods: {
        toggle () {
            this.sharedState.active = !this.sharedState.active
        },
        close() {
            this.sharedState.active = false
        }
    },

    watch: {                    
        $route() {     
            this.close()
        }
    }
}
</script>

<style lang="stylus" scoped>
    div.drop-down-container
        margin-right: 1rem
        margin-top: 1.5rem
        button 
            padding 0
            font-size 1.5rem
            background none
            border none
            margin 0
            margin-left 1rem
            
            &:hover 
                transform scale(1.09)
                cursor pointer 

            img 
                height 2.75rem
                

@media screen and (max-width: 1000px)
    div.drop-down-container
        margin-top: 1rem
        button
            img 
                height 2.5rem

@media screen and (max-width: 600px)
    div.drop-down-container
        button
            img 
                height 2rem
</style>
<template>
    <button alt="delete button" class='trash-btn'  type="button">
        <img :class="this.size" src="@/assets/img/delete.svg" alt="trash icon">
    </button>
</template>

<script>
export default {
    props: ['size']
}
</script>

<style lang="stylus" scoped>
    button.trash-btn 
        // padding 0
        // font-size 1.5rem
        background none
        border none
        margin 0

        img 
            filter var(--svg-filter)

        &:hover
            filter brightness(0.3) sepia(1) hue-rotate(-70deg) saturate(12)
            cursor pointer
        img.one 
            height 1.5rem

        img.two 
            height 2rem

        img.three 
            height 3rem
</style>
<template>
<div class="header-container">
    <img v-if="isDark" src="@/assets/img/blue.svg" alt="logo">
    <img v-else src="@/assets/img/magenta-logo.svg" alt="logo">
</div>
</template>


<script>

export default {
    computed: {

        isDark() {
            let bool
            let theme = localStorage.getItem('theme')
            if (theme === "dark" || !theme) {
                bool = true
            } else { bool = false}
            return bool
        },
        
    }
}

</script>

<style lang="stylus" scoped>

    img 
        width: 12rem;
        height: 12rem;
        animation spin 6s 
        animation-iteration-count: 1;

        &:hover 
            transform: rotate(720deg);
            transition: transform 5s ease-in-out;

@media all and (max-width: 600px)
    img
        width: 10rem;
        height: 10rem;
    
</style>
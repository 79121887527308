export default {

    setEmail(email) {
        window.sessionStorage.setItem('email', email);
    },

    setOwner(owner) {
        window.sessionStorage.setItem('owner', owner);
    },

    setToken(token) {
        window.sessionStorage.setItem('token', token);
    },

    setRefresh(refresh) {
        window.sessionStorage.setItem('refresh', refresh);
    },

    getEmail() {
        return window.sessionStorage.getItem('email');
    },

    getOwner() {
        return window.sessionStorage.getItem('owner');
    },

    getToken() {
        return window.sessionStorage.getItem('token');
    },

    getRefresh() {
        return window.sessionStorage.getItem('refresh');
    },

    clear() {
        window.sessionStorage.removeItem('email');
        window.sessionStorage.removeItem('owner');
        window.sessionStorage.removeItem('token');
        window.sessionStorage.removeItem('refresh');
    },

    isAuthenticated() {
        return window.sessionStorage.getItem('token') !== null;
    },

    authenticate(to, from, next) {
        if (window.sessionStorage.getItem('token') !== null) {
            next();
        } else {
            next('/auth/login');
        }
    },

    isValidEmail(email) {
        return email !== null &&
        email.length >= 6 &&
        email.includes('@');
    },

    isValidPassword(password) {
        return password !== null &&
        password.length >= 6;
    }
}

<template>
<div class="center-panel">
    <div class="inner-panel">  

        <p class="heading">Add subject to</p>
        <h2 class="project">{{projectName}}</h2>

        <form id="add-subject" @keyup.enter="submitSubject">
            <label for="first-name">First Name</label>
            <input id='first-name' type="text" v-model="firstName" required >
            <label for="last-name">Last Name</label>
            <input id='last-name' type="text" v-model="lastName" required >

            <template v-for="(item, i) in schema" :key="i">
                <label :for="item">{{item}}</label>
                <input :id='item' type="text" v-model="groups[i]" >
            </template>

        </form>

        <flex-row class="button-row">
            <button class="cancel" @click="close">Cancel</button>
            <button class='submit' @click="submitSubject" v-if="isFilled()">Submit</button>
        </flex-row>

    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            firstName: '',
            lastName: '',
            groups: []
        }
    },
    methods: {

        async submitSubject() {
            if (this.isFilled()) {
                let subjectGroups = {};
                if (this.schema.length > 0) {
                    subjectGroups = Object.assign(...this.schema.map((k, i) => ({[k]: this.groups[i]})));
                }
                let payload = {
                    firstName: this.firstName.trim(),
                    lastName: this.lastName.trim(),
                    groups: subjectGroups
                }
                try {
                    await this.$store.dispatch('addSubject', payload);
                    await this.$store.dispatch('fetchSubjects');
                    this.$router.back()
                } catch(err){
                    await this.$store.dispatch('callToast', 'Error: Failed to add subject');
                }
            }

        },

        isFilled() {
            return this.firstName != '' &&
            this.lastName != '' &&
            this.groups.every(grp => grp != '')
        },

        close() {
            const id = this.$store.state.activeProject.id;
            this.$router.push({name: 'find-subjects', params: {id: id}});
        }
    },
    
    computed: {

        schema() {
            let headers = [...this.$store.state.activeProject.schema.header];
            return headers.sort();
        },

        projectName() {
            return this.$store.state.activeProject.name
        },

    },

    mounted() {
        document.getElementById('first-name').focus()
        for (let i = 0; i < this.schema.length; i++) {
            this.groups.push('');
        }
    }
    
}
</script>

<style lang="stylus" scoped>

div.center-panel
    width 80%
    max-width 60rem
    margin-top 3rem
    margin-bottom 3rem
    div.inner-panel
        background var(--brighter-panel)
        border-radius 12px
        display flex
        flex-flow column
        align-items center
        justify-content center
        padding 2rem
        overflow hidden

        p.heading 
            margin 0
            font-size 1.75rem

        h2.project
            font-size 2rem
            margin 0.5rem 0 1.5rem 0

        form 
            display flex 
            flex-flow column
            align-items center
            
            label
                border none
                font-size: 1.5rem
                margin-bottom 0.5rem
                &:first-child
                    margin-top 1rem
                    
            input
                -webkit-appearance: none; 
                appearance: none;
                font-size 1.3rem
                padding: 0.5rem
                margin-bottom 1rem
                display: inline-block;
                border solid 2px var(--border)
                border-radius: 7px;
                box-sizing: border-box;
                color var(--text)
                text-align center
                background-color: var(--panel)
                font-family var(--font)
                
                &:focus
                    background var(--bg)
                    
                &::placeholder 
                    opacity 1
                    text-align center
                    color var(--text)

        div.button-row 

            button
                -webkit-appearance: none; 
                appearance: none;
                background var(--charcoal)
                color var(--text-color)
                font-family var(--font)
                font-size 1.5rem
                border none 
                border-radius 7px
                box-shadow 1px 1px 3px 1px rgba(0,0,0,.2)
                padding 0.75rem 1rem
                margin 1.5rem 1rem 1rem 1rem

                &:hover
                    cursor pointer
                    transform scale(1.03)

            button.submit
                background-color: var(--accent)

                

    select.form-select::placeholder
        text-align center

@media screen and (max-width: 1000px)
    div.center-panel
        margin-top 2rem
        margin-bottom 2rem
        div.inner-panel 
            p.heading 
                font-size 1.5rem
            h2.project
                font-size 1.75rem
            form
                label
                    margin-top 0.75rem
                    font-size: 1.3rem
                input 
                    font-size 1.2rem
            div.button-row
                button
                    font-size 1.4rem


@media screen and (max-width: 600px)
    div.center-panel
        div.inner-panel 
            p.heading 
                font-size 1.3rem
            h2.project
                font-size 1.5rem
            form
                label 
                    font-size: 1.2rem
                    margin-top: 0.5rem
                input 
                    font-size 1rem
            div.button-row
                button
                    font-size 1.1rem


</style>
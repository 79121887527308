<template>
    <div class="nav">
        <span class="title">Shutter<span>Sort</span></span>
        <span class="version">v1.0.1</span>
        <div class="bar-container"></div>
        <div :class="{disabled: isSafe}"><drop-down/></div>
        
    </div>
</template>

<script >
import DropDown from './DropDown.vue'
export default {
    components: { DropDown },
    methods: {
        async logoutUser() {
            await this.$store.dispatch("logout")
            this.$router.push('/')
        },
    },
    computed: {
        isSafe() {
            return this.$store.state.uiStore.isSafe;
        }
    }
}
</script>

<style scoped lang="stylus">
    div.nav 
        height 4rem
        display flex
        flex-flow row 
        align-items: center
        color var(--text)
        margin-top 0.5rem
        z-index: 100

        span.title 
            font-size 3.25rem
            font-family: var(--title-font)
            color var(--title)
            margin-left 1rem
            margin-top 0.5rem
            span    
                opacity .7

        span.version 
            margin-left .3rem
            font-family var(--title-font)
            font-size 1.25rem
            height .6rem
            color var(--text)
            line-height 2.5rem


        div.bar-container 
            width 100%  
            display flex
            flex-flow row
            justify-content center   

    div.disabled
        pointer-events: none
        filter opacity(20%)

@media screen and (max-width: 1000px)
    div.nav
        margin-top 0
        span.title 
            font-size 2.5rem
        span.version 
            font-size 1rem

@media screen and (max-width: 600px)
    div.nav
        span.title 
            font-size 2rem
        span.version 
            font-size 0.75rem
    

</style>

<template>
    <div>
        <label :for="id" class="check" @click="pickHeader" v-bind:class="{checked: isChecked}">
            <svg width="18px" height="18px" viewBox="0 0 18 18">
                <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                <polyline points="1 9 7 14 15 4"></polyline>
            </svg>
            {{headerName}}
        </label>
    </div>

</template>

<script>
export default {
    props: ['id', 'headerName'],
    emits: ["chooseheader"],
    data() {
        return {
            isChecked: false
        }
    },
    methods: {
        pickHeader() {
            this.isChecked = !this.isChecked
            const payload = {"id": this.id, "isChecked": this.isChecked}
            this.$emit("chooseheader", payload)
        }
    }
}
</script>

<style lang="stylus" scoped>
div
    margin 1rem
    white-space nowrap

    label.check
        display: inline-block
        cursor: pointer
        font-size 1.5rem

        &:hover 
            &:before
                opacity: 1
            svg
                stroke: #4285F4

    svg
        display: inline-block
        margin-right 0.5rem
        z-index: 1
        fill: none
        stroke-linecap: round
        stroke-linejoin: round
        stroke: #C8CCD4
        stroke-width: 1.5
        transform: translate3d(0,0,0)
        transition: all .2s ease
        path
            stroke-dasharray: 60
            stroke-dashoffset: 0
        polyline 
            stroke-dasharray: 22
            stroke-dashoffset: 66

    

    .checked
        svg
            stroke: #4285F4
            path
                stroke-dashoffset: 60
                transition: all .3s linear
            polyline
                stroke-dashoffset: 42
                transition: all .2s linear
                transition-delay: .15s

@media screen and (max-width: 1000px) 
    div
        margin 0.75rem
        label.check
            font-size 1.25rem

@media screen and (max-width: 600px)
    div
        margin 0.5rem
        label.check
            font-size 1rem
</style>
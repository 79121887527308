<template>
    <div class="base" ref="base">
        <site-nav/>
        <router-view  class="site"/>
        <legal-notice/>
    </div>
</template>

<script>
import SiteNav from '@/components/site/SiteNav.vue'
import LegalNotice from '@/components/common/LegalNotice.vue'
export default {
    components: { SiteNav, LegalNotice},
}
</script>

<style lang="stylus" scoped>

div.base
    display: flex
    flex-direction: column
    flex-grow: 1
    height 100%
    min-height: 100vh
    background-image: url('../../assets/img/background-2.png')
    background-attachment: fixed;
    background-position: center;

    .site
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color var(--text)
        font-family: protipo, sans-serif;

    *
        color var(--text)
    
    div.legal-notice
        text-align: center
        background-color: var(--solid-panel)

@media all and (max-width: 1000px) 
    div.legal-notice
        font-size: 0.9rem
    
@media all and (max-width: 600px) 
    div.legal-notice
        font-size: 0.8rem
</style>

import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes/index'


export default createRouter({
    routes,
    history: createWebHistory(),
    scrollBehavior (to, from, savedPosition) { 
        return savedPosition
    }
})



<template>
<div class="base">
    <div class="container">
        <div class="heading">
            <span>4</span>
            <img src="@/assets/img/blue.svg" alt="logo">
            <span>4</span>
        </div>
        <h2>Page not found</h2>
        <router-link alt="login page link" to="/auth/login">Try logging in</router-link>
        <div class="or"><span>or</span></div>
        <router-link alt="projects page link" to="/">Visit site</router-link>
    </div>
</div>
</template>

<script>

</script>

<style scoped lang="stylus">

div.base 
    display: flex
    flex-direction: column
    flex-grow: 1
    font-family var(--font)

    @keyframes spin 
        0% { transform: rotate(0deg) }
        100% { transform: rotate(360deg) }

    div.container
        margin auto
        margin-top 10rem
        text-align: center
        background var(--panel)
        // width 45%
        padding 3rem
        border-radius 7px

        div.heading 
            display: flex
            justify-content: center
            align-items: center
            font-size 8rem
            font-family var(--font)
            color var(--text)
            span 
                margin 1rem

            
            img 
                width 7rem
                height 7rem
                animation spin 6s 
                animation-iteration-count: 1;

                &:hover 
                    transform: rotate(720deg);
                    transition: transform 5s ease-in-out;

        h2
            color var(--text)
            font-family var(--font)
            font-size 3rem
            margin-top 1rem
            margin-bottom 2rem
            text-align: center
        a  
            text-decoration none 
            text-align: center
            font-size 2rem
            font-family var(--font)
            color var(--link-text)
            
            &:hover
                color var(--accent)
        
        div.or 
            color var(--text)
            margin 1rem
            font-size 2rem



@media all and (max-width: 1000px)
    div.base
        div.container
            // width 50%
            div.heading
                font-size: 6rem
                img 
                    width 5rem
                    height 5rem
                span 
                    margin 0.5rem
            h2
                font-size 2rem
                margin-top 0

            a  
                font-size 1.5rem

            div.or 
                font-size 1.5rem
    

@media all and (max-width: 600px)
    div.base
        div.container
            // width 70%
            div.heading
                font-size: 4rem
                img 
                    width 3rem
                    height 3rem
            h2
                font-size 1.5rem
                margin-top 0

            a  
                font-size 1.25rem

            div.or 
                font-size 1.25rem
                margin 0.5rem

</style>
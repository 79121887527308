<template>
<div class="outer-container">
    <div class="center-col">
        <subject-search-bar v-if="hasSubjects" />
        <div class="list-div">
            <div v-if="hasSubjects && subjects.length <= listLimit" class="list-container" ref="listContainer">
                <template v-for="(subject, i) in subjects" :key='i'>
                    <div class='subject-row'>    
                        <list-item :subject='subject'/>
                        <button 
                            class='edit-btn'  
                            type="button"
                            v-if="isNotSafe && editMode"
                            @click="editSubject(subject.id)"
                        >
                            <img src="@/assets/img/edit.svg">
                        </button>
                        <trash-btn 
                            class='trash-btn'
                            v-if="isNotSafe && deleteMode" 
                            size="two" 
                            @click="deleteSubject(subject)"
                        />
                    </div>
                </template>
            </div>
            <div class="large-warning" v-else-if="subjects.length > listLimit">
                <h2>There are too many subjects to list</h2>
                <p>Start searching to narrow down the selection</p>
            </div>
            
            <!-- <div v-if="noFilters && hasGroups">
                <h1>Make a selection or search for a subject. </h1>
            </div> -->
            <div v-if="!hasSubjects" class="no-subjects-message">
                <h2>This Project has no subjects</h2>
                <p>Add subjects individually with the add subject button</p>
            </div>
        </div>
        
        
    </div>

    <toggle-mode-panel
        v-if="hasSubjects && subjects.length <= listLimit && isNotSafe"
        :delete-mode="deleteMode"
        :edit-mode="editMode"
        @set-delete-mode="setDeleteMode"
        @set-edit-mode="setEditMode"
    />

    <add-subject-panel/>
    
    <popup v-if="deleteConfirmVisible" @close="deleteConfirmVisible = false">
        <delete-confirm 
            :name="name"
            @close="deleteConfirmVisible = false"
            @delete="confirmDelete"
        />
    </popup>
    
</div>
</template>

<script>
import DeleteConfirm from './DeleteConfirm.vue'
import Popup from '@/components/common/Popup.vue'
import ToggleModePanel from './ToggleModePanel.vue'
import AddSubjectPanel from './AddSubjectPanel.vue'
import ListItem from "./ListItem.vue"
import SubjectSearchBar from "./SubjectSearchBar.vue"
import TrashBtn from '@/components/common/TrashBtn.vue'

export default {

    components: {
        ListItem, SubjectSearchBar, TrashBtn, DeleteConfirm, ToggleModePanel, AddSubjectPanel, Popup
    },

    data(){
        return {
            listKey: 1,
            id: null,
            name: null,
            deleteConfirmVisible: false,
            deleteMode: false,
            editMode: false
        }
    },

    watch: {
        $route(to) {
            // reset the query unles we're going to the code page
            if (to.name != "code") {
                this.$store.commit('setQuery', '');
            }
        }
    },

    methods: {
        async editSubject(id) {
            this.$store.commit('setSubjectToEdit', id)
            this.$router.push({name: "edit-subject", params: {id: id}})
        },
        async deleteSubject(subject) {
            this.id = subject.id;
            this.name = subject.firstName + ' ' + subject.lastName;
            if (this.quitAsking) {
                await this.confirmDelete();
            } else {
                this.deleteConfirmVisible = true;
            }
        },
        async confirmDelete() {
            try {
                await this.$store.dispatch('deleteSubject', this.id); 
            } catch {
                await this.$store.dispatch('callToast', 'Error: Failed to delete subject');
            }
            await this.$store.dispatch('fetchSubjects');
        },

        setDeleteMode(flag) {
            this.deleteMode = flag;
            if (flag) {
                this.editMode = false;
            }
        },
        setEditMode(flag) {
            this.editMode = flag;
            if (flag) {
                this.deleteMode = false;
            }
        }
    },

    computed: {
        subjects() {
            return this.$store.getters.searchSubjects
        },
        hasSubjects() {
            return this.$store.getters.hasActiveSubjects
        },
        // noFilters() {
        //     let bool = false
        //     if (Object.keys(this.$store.state.groups).length > 0) {bool = false}
        //     if( this.$store.state.uiStore.select0.value === null &&
        //         this.$store.state.uiStore.select1.value === null &&
        //         this.$store.state.uiStore.select2.value === null &&
        //         this.$store.state.uiStore.query.length === 0
        //     ) {bool = true} else {bool = false}
        //     return bool
        // },
        hasGroups() {
            let bool = false
            if(Object.keys(this.$store.state.activeProject.schema.header).length > 0) {
                bool = true
            }
            return bool
        },
        isNotSafe() {
            return !this.$store.state.uiStore.isSafe
        },
        quitAsking() {
            return this.$store.state.uiStore.quitAsking
        },
        listLimit() {
            return this.$store.state.listLimit
        }
    },

    // async beforeCreate() {
        
    //     if (this.$store.state.subjects.length == 0) {
    //         this.$store.commit('setSelectsAll')
    //         // await this.$store.dispatch('fetchProjects');
    //         await this.$store.dispatch('fetchSubjects');
            
    //     }
    // }
}
</script>

<style scoped lang='stylus'>
div.outer-container
    // overflow-y: auto
    height 100%
    width 100%
    div.center-col
        // background-color: var(--panel)
        // background: navy
        height 100%
        
        display: flex
        flex-flow: column
        align-items: center

        h1
            margin 1.5rem
            font-size 2.75rem
            font-weight normal
            text-align: center

        div.list-div
            // overflow-y: auto
            width 100%
            margin-top 10rem
            
            div.list-container
                display flex
                flex-flow column
                align-items center
                font-size calc(1rem + 1vw)
                font-family: var(--font)
                text-align center
                // overflow-y: auto
                height: calc(100% - 13.25rem)
                // background: blue
                width 18rem
                margin auto
                margin-bottom 1rem
                
                div.subject-row 
                    display flex
                    flex-flow row
                    align-items center
                    width 100%

                    button.edit-btn 
                        padding 0
                        font-size 1.5rem
                        background none
                        border none
                        margin 0
                        margin-left 1rem
                        
                        &:hover 
                            transform scale(1.09)
                            cursor pointer 


                        img 
                            height 2rem

                    button.trash-btn 
                        margin-left 0.5rem
                        margin-right 0.5rem

            div.no-subjects-message
                background-color var(--panel)
                padding 1rem
                text-align: center
                border-radius: 7px
                margin auto
                width clamp(16rem, 50vw, 40rem)
                h2
                    font-size 1.75rem
                    color var(--warn)
                    margin 1rem
                p
                    font-size 1.5rem
                    margin 1rem

            div.large-warning
                margin auto
                background-color var(--panel)
                padding 1rem
                border-radius: 7px
                text-align: center
                align-self: center
                width clamp(16rem, 50vw, 40rem)
                h2
                    font-size 1.75rem
                p
                    font-size: 1.5rem

@media screen and (max-width: 1000px)
    div.outer-container
        div.center-col
            h1 
                margin 1rem
                font-size 2.25rem
            div.list-div
                margin-top 9rem
                div.list-container
                    height: calc(100% - 11.75rem)
                div.large-warning
                    h2
                        font-size 1.5rem
                    p
                        font-size 1.35rem
                div.no-subjects-message
                    h2
                        font-size 1.5rem
                    p
                        font-size 1.35rem

@media screen and (max-width: 600px)
    div.outer-container
        div.center-col
            h1 
                margin 0.75rem
                font-size 1.5rem
            div.list-div
                margin-top 8rem
                div.list-container
                    width 14rem
                    height: calc(100% - 8.75rem)
                div.large-warning
                    h2
                        font-size 1.25rem
                        margin 1rem
                    p
                        font-size 1.1rem
                        margin 1rem
                div.no-subjects-message
                    h2
                        font-size 1.25rem
                        margin 1rem
                    p
                        font-size 1.1rem
                        margin 1rem

</style>
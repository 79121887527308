import axios from 'axios'
import store from '@/store'
const API_KEY = "AIzaSyDI-mwBAhtv9G19dMt0a3F6azAuM-jhPQM"

const authClient = axios.create({
    baseURL: "https://identitytoolkit.googleapis.com/v1",
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
    },
    params: {
        'key': API_KEY
    }
});

// authClient.interceptors.request.use(req => {
//     console.log(`DATA: ${req.data} | HEADERS: ${JSON.stringify(req.headers)} | ${req.method} |  BASEURL: ${req.baseURL} | URL: ${req.url} `);
//     return req;
// });
authClient.interceptors.request.use((config) => {
    store.commit('setLoading', true)
    return config
})
authClient.interceptors.response.use((response) => {
    store.commit('setLoading', false)
    return response
})

const errorInterceptor = error => {
    store.commit('setLoading', false)
    // check if it's a server error
    if (!error.response) {
        return Promise.reject(error);
    } else {
        const code = error.response.data.error.code;
        const message = error.response.data.error.message;
        // this only happens as a result of trying to send too many email verification
        // codes in too short a period of time
        if (code == 400 && message == "TOO_MANY_ATTEMPTS_TRY_LATER") {
            store.dispatch('callToast', `Please check your email for verification link`)
        } else if (code == 400 && message.includes("TOO_MANY_ATTEMPTS_TRY_LATER") && message.includes("many failed login attempts")) {
            store.dispatch('callToast', `Error: Too many login attempts. Please reset password or try again later.`)
        } else if (code == 400 && (message == "EMAIL_NOT_FOUND" || message == "INVALID_PASSWORD")) {
            store.dispatch('callToast', `Error: Invalid login credentials`)
        } else if (code == 400 && message == "EMAIL_EXISTS") {
            store.dispatch('callToast', 'Error: Account already exists')
        } else {
            store.dispatch('callToast', `Unknown Error`)
        }
        return Promise.reject(error);
    }
}

// Interceptor for responses
const responseInterceptor = response => {
    switch(response.status) {
        case 200: 
            break;
        // any other cases
        default:
        // default case
    }

    return response;
}

authClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export { authClient }
<template>
    <div class="container">
        <h1>{{projectName}}</h1>
        <input alt="search for subject by typing last name" v-model="getQuery" type="search" placeholder="Search for a subject by last name">
    </div>
</template>

<script>
export default {
    computed: {
        
        getQuery: {
            get() {
                return this.$store.state.uiStore.query
            },
            set(payload) {
                this.$store.commit('setQuery', payload)
            }
        },
        projectName() {
            return this.$store.state.activeProject.name
        },
    },
    
}
</script>

<style lang="stylus" scoped>
div.container
    width: 30rem
    margin 0
    padding 0
    position fixed
    top 6.5rem
    text-align center
    background-color: hsla(269, 32%, 16%, 1) // approx var(--bg) + var(--panel)
    z-index 1
    h1 
        font-size 3rem
        margin 1rem 0 1rem 0
    input 
        width 100%
        font-size: 1.5rem
        padding 0.5rem
        border solid 2px var(--border)
        border-radius: 7px;
        color var(--text)
        text-align center
        background-color: var(--panel)
        font-family var(--font)
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 1rem

        &:focus
            background var(--charcoal)
            
        &::placeholder 
            opacity 1
            text-align center
            color var(--text)

@media screen and (max-width: 1000px)
    div.container
        width 25rem
        top 10rem
        h1 
            font-size 2.5rem
        input 
            font-size: 1.25rem

@media screen and (max-width: 800px)
    div.container
        top 9.5rem

@media screen and (max-width: 600px)
    div.container
        width 20rem
        top 9rem
        h1 
            font-size 1.5rem
        input 
            font-size: 1.1rem
</style>
<template>
    <h1 class="logo">Shutter<span>Sort</span></h1>
</template>

<style scoped lang="stylus">

    h1.logo 
        padding 0rem
        margin 0rem
        color: rgb(220, 220, 220);
        font-family: bc-alphapipe, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size 2.7rem
    
        span 
            color: rgb(163, 163, 163);

</style>